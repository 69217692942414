import React, { useEffect, useState , useRef } from "react";
import { Col, Row, Form, Button, Switch, Input, Select, Spin } from "antd";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { pageSettingList, pageSettingListUpdate } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { pageSettingVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";
import { JSDOM } from 'jsdom';


const { TextArea } = Input;

export default function PageSettting() {
    const [form] = Form.useForm();
    const {issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);

    let [pageSetting,     setPageSetting] = useState("");
    let [selectoptions,   setSelectOptions] = useState([]);
    let [isLoading,       setLoading] = useState(false);
    let [isDisabled,      setDisable] = useState(false);

    const [checkingMessage, setCheckingMessage] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        getPageSettingData();
    }, []);

    const getPageSettingData = async () => {

        let requestData = {
            columnRequire : ['Name', 'HeadScript', 'BodyScriptStart', 'BodyScriptEnd', 'RegisterScript', 'LoginScript', 'DepositScript', 
                            'WithdrawScript','MetaTitle','Description','Keyword','CanonicalTag','OgUrl','OgTitle','OgDescription','OgImage', 
                            'SeoArticle', 'HeaderTag', 'Sitemap'],
        }

        const result = await pageSettingList(requestData); 
        const response = await result.json();
        if (response.status == "ok") {
            response.data.map((element, index) => selectoptions.push(element.Name));
    
            form.setFieldsValue({
                Name: response.data[0].Name,
                HeadScript: response.data[0].HeadScript,
                BodyScriptStart: response.data[0].BodyScriptStart,
                BodyScriptEnd: response.data[0].BodyScriptEnd,
                RegisterScript: response.data[0].RegisterScript,
                LoginScript: response.data[0].LoginScript,
                DepositScript: response.data[0].DepositScript,
                WithdrawScript: response.data[0].WithdrawScript,
                MetaTitle: response.data[0].MetaTitle,
                Description: response.data[0].Description,
                Keyword: response.data[0].Keyword,
                CanonicalTag: response.data[0].CanonicalTag,
                OgUrl: response.data[0].OgUrl,
                OgTitle: response.data[0].OgTitle,
                OgDescription: response.data[0].OgDescription,
                OgImage: response.data[0].OgImage,
                SeoArticle: response.data[0].SeoArticle,
                HeaderTag: response.data[0].HeaderTag,
                Sitemap: response.data[0].Sitemap,
            })
        }
        else {
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);
        }
    }

    const onSubmit = async (data) => {
        setDisable(true);
        setLoading(true);

        const finalResult = validateAndUpdateHeadSection(data.HeaderTag);
        console.log(finalResult);

        if (finalResult.success) {
            form.setFields([
                {
                    name: 'HeaderTag',
                    errors: undefined
                }
            ])
            const result = await pageSettingListUpdate(data);
            const response = await result.json();
            if (response.status == "ok") {
                getPageSettingData();
                alertData.title = "Success";
                alertData.msg = response.msg;
                sharedSuccessAlert(alertData);
            }
            else if (response.errcode == "1101") {
                window.localStorage.clear();
                window.location.href = "/logout";
                return alert("Session expired ! please login again !");
            }
            else {
                alertData.title = "Failed";
                alertData.msg = response.msg;
                sharedFailAlert(alertData);
            } 
        } else {
            form.setFields([
                {
                    name: 'HeaderTag',
                    errors: ['']
                }
            ])
            form.scrollToField('HeaderTag', {
              behavior: 'instant',
              block: 'center',
            });
        }

        setLoading(false);
        setDisable(false);
    }

    const handleSelectChange = (optionData) => {
        getPageSettingData(optionData)
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
    };
    const taillayout= {
        wrapperCol: { span: 2 }
    }

    function validateAndUpdateHeadSection(content) {
        try {
            // Validate the new content
            if (content !== '' && content !== null) {
                const syntaxCheck = validateContent(content);
                
                if (!syntaxCheck.validationResult.valid) {
                    setCheckingMessage(syntaxCheck.validationResult.error);
                    throw new Error(syntaxCheck.validationResult.error);
                }
            }
            setCheckingMessage('');
            return { success: true, message: 'Head section updated successfully' };
        } catch (error) {
            return { success: false, message: error.message };
        }
    }

    function validateContent(content) {

        const dom = new JSDOM(`<!DOCTYPE html><html><head>${content}</head></html>`);
        const errors = [];

        const title = dom.window.document.head.querySelector("title");
        if (title) {
            title.remove();
        }

        const parsedHead = dom.window.document.head.innerHTML;
        
        if (parsedHead !== content) {
            const scripts = dom.window.document.querySelectorAll("script");
            scripts.forEach((script) => {
                script.textContent = "...";
            });
            const newParsedHead = dom.window.document.head.innerHTML;
            errors.push(`Syntax error${newParsedHead && `, suggested solution:\n${newParsedHead}`}`);
        }

        const body = dom.window.document.body;
        if (body.children.length > 0) {
            const bodyTitle = dom.window.document.body.querySelectorAll("title");
            bodyTitle.forEach((title) => {
                title.textContent = "...";
            });
            errors.push(`\nThese elements do not belong in the head section:\n${body.innerHTML}`);
        }

        if (errors.length > 0) {
            return {
                validationResult: {
                    valid: false,
                    error: errors
                }
            };
        }

        return {
            validationResult: { valid: true }
        };
    }

    const styles = {
        checkText: {
            color: 'red',
            whiteSpace: 'pre-line'
        },
        showMoreText: {
            color: '#007bff',
            cursor: 'pointer'
        }
    }
    
    return (
        <div>

        {                 
        pageSettingVw(role, username, compCode) ?

            <Form {...layout} form={form} name="control-hooks" onFinish={onSubmit} style={{marginTop: 24}}>
            
                <Form.Item name="Name" label="Name" rules={[{required: true}]}>
                    <Select placeholder="Select..." onChange={handleSelectChange} disabled={isDisabled} style={{ width: 120 }}
                            options={ [{value: 'all', label: 'all'}] }
                        />
                </Form.Item>
                <Form.Item label="Header Tag">
                    <Form.Item
                        name="HeaderTag"
                        noStyle
                    >
                        <TextArea disabled={isDisabled} rows={4}/> 
                    </Form.Item>
                    {checkingMessage && 
                        <div style={styles.checkText}>
                            {showMore ? checkingMessage : checkingMessage[0]}
                            {checkingMessage.length > 1 && 
                                <div style={styles.showMoreText} onClick={() => setShowMore(!showMore)}>
                                    {showMore ? 'show less' : 'show more'}
                                </div>}
                        </div>}
                </Form.Item>
                <Form.Item name="HeadScript" label="Header Script">
                    <TextArea disabled={isDisabled} rows={4} />
                </Form.Item>
                <Form.Item name="BodyScriptStart" label="Body Script Start">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="BodyScriptEnd" label="Body Script End">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="RegisterScript" label="Register Script">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="LoginScript" label="Login Script">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="DepositScript" label="Deposit Script">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="WithdrawScript" label="Withdraw Script">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="MetaTitle" label="Meta Title">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="Description" label="Description">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="Keyword" label="Keyword">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="CanonicalTag" label="Canonical Tag">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="OgUrl" label="Og Url">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="OgTitle" label="Og Title">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="OgDescription" label="Og Description">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="OgImage" label="Og Image">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="SeoArticle" label="Seo Article">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="Sitemap" label="Sitemap">
                    <TextArea disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item {...taillayout} style={{justifyContent: 'center'}}>
                        <Button type="primary" loading={isLoading} disabled={isDisabled} htmlType="submit"> Submit </Button>
                </Form.Item>

            </Form>   
            :
            <CNotAuthorized/> 
        }

        </div>
    )


    
  }