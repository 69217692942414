import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { backendLoginVerify, getPageAccess } from "../../config/networkConfig";
import md5 from "md5";
import { Logo } from "../../common/components/Logo";

export default function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const md5Pass = md5(password);
  const [isIframeLogin, setIsIframeLogin] = useState(false);

  // Event listener for receiving login credentials via postMessage
  useEffect(() => {
    const handleMessage = (event) => {

      const { iframeUser, iframePass } = event.data;

      if (iframeUser && iframePass) {
        setUsername(iframeUser);
        setPassword(iframePass);
        setIsIframeLogin(true);
      } else {
        console.error("Received invalid data from iframe:", event.data);
      }
    };

    // Attach the event listener when the component is mounted
    window.addEventListener('message', handleMessage);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (isIframeLogin && username && password) {
      setIsIframeLogin(false);
      onPress();
    }
  },[isIframeLogin, username, password])

  const onPress = () => {
    if (username.length === 0 || password.length === 0) {
      message.error("Please enter username or password");
    } else {
      backendLoginVerify({ username: username, pass: md5Pass })
        .then((response) => response.json())
        .then((json) => {
          const status = json.status;
          if (status === "ok") {
            dispatch({ type: "sign_in", payload: json.data });
            const userId = json.data.userId;
            dispatch({ type: "sign_in_start_loading" });
            getPageAccess({userId})
              .then((res) => res.json())
              .then((json) => {
                if (json.status === 'ok') {
                  dispatch({ type: "sign_in_get_access", payload: json.data });
                }
                dispatch({ type: "sign_in_stop_loading" });
              })
          } else {
            message.error("Username or Password wrong");
            document.getElementById("normal_login_password").select();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="loginBody">
      <div className="loginLogo">
        <Logo logoType="login"/>
        {/* <img src={logo.logo} alt="Logo" style={{ maxWidth: "80%" }} /> */}
      </div>

      <div className="loginFieldBg">
        <div style={{ margin: 10 }}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <Form.Item
              preserve={false}
              name="username"
              rules={[
                { required: true, message: "Please input your Username!" },
              ]}
            >
              <Input
                style={{ height: "50px" }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                style={{ height: "50px" }}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item>
              <Link to="/">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={onPress}
                >
                  Login
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
