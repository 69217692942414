import { Base64 } from "js-base64";
import {
  domain,
  uploadDomain,
  botDomain,
  topupReceiptUploadDomain,
  saveOrOpdateCssDraftDomain,
  updateCssVariablesDomain,
  modifierUploadImageDomain,
} from "./config";
import Store from "../redux/store";
import { logout } from "../config/route";

import moment from "moment";
export {
  loginTokenCheck,
  backendLoginVerify,
  backendLoginToken,
  userCompanyNew,
  userCompanyList,
  userManageUpdate,
  userManageUpdateV2,
  userBankList,
  userShareholderNew,
  userShareholderList,
  userShareholderListV2,
  userDownlineNew,
  userAgentList,
  userAgentListV2, //used by TKB777
  userAgentNew,
  userCSNew,
  userCSList,
  csWithdrawList,
  csWithdrawListV2,
  csWithdrawListV3,
  csWithdrawListV4,
  getProcessingWithdrawList,
  getUnconfirmPuppetList,
  getUnconfirmPuppetListV2,
  reprocessUnconfirmTrxn,
  clearUnconfirmTrxn,
  rejectUnconfirmTrxn,
  getTrxnScreenshot,
  csWithdrawCheck,
  csWithdrawCheckV2,
  csWithdrawCheckV3,
  csWithdrawReject,
  csWithdrawRejectV2,
  csWithdrawRejectV3,
  csWithdrawRejectV4,
  csWithdrawRejectV5,
  csWithdrawApprove,
  csWithdrawApproveV2,
  csWithdrawApproveV3,
  csWithdrawApproveV4,
  csWithdrawManualApprove,
  csWithdrawManualApproveV2,
  csWithdrawManualApproveV3,
  csWithdrawAccept,
  csWithdrawComplete,
  csDepositList,
  csDepositListV1,
  csDepositApprove,
  csDepositManualApprove,
  csBankConflictApprove,
  csBankConflictApproveBankApp,
  csBankConflictApproveBankAppV3,
  csBankConflictApproveBankAppV4, // used by TKB777 only
  csBankConflictApproveBankAppV5, // for all brand
  csBankConflictApproveBankAppV6,
  csUpdateBankAccName,
  csResolveUnclaimDeposit,
  csDepositReject,
  csDepositFollowupUpdate,
  csDepositAdjustNew,
  csDownlineTopupList,
  csDownlineTopupListSummary,
  csDownlineTopupUpdate,
  csMemberList,
  csMemberListV2,
  csMemberListV3,
  csMemberListAcctnum,
  csMemberListAcctnumV2,
  csMemberListGameId,
  csCreateMember,
  csCreateMemberV2,
  csUpdateMember,
  userMemberLock,
  userMemberActive,
  userMemberInactive,
  userMemberLockV2,
  userMemberActiveV2,
  userMemberInactiveV2,
  userDLSummary,
  bankManageList,
  bankManageNew,
  bankManageUpdate,
  csWithdrawBank,
  csBankList,
  csAvailBankList,
  csTransactionServiceList,
  csVerifyMobile,
  getBindGameUserList,
  csCreateWithdrawal,
  csCreateWithdrawalV4,
  csMemberWalletSummary,
  csMemberWalletTrxn,
  csMemberWalletTrxnMobile,
  csMemberGameSummary,
  csMemberGameTrxn,
  csWithdrawUpdate,
  csWithdrawResortList,
  csWithdrawResortUpdate,
  csMemberUpline,
  csMemberDownline,
  csMaintenanceList,
  csMaintenanceUpdate,
  csMemberFeedbackList,
  csMemberFeedbackUpdate,
  csMemberDownlineList,
  csMonitorBankVerify,
  csMonitorDeposit,
  csMonitorWithdraw,
  csMonitorWatchList,
  bankMachineList,
  getBankDetailList,
  getBankMachList,
  updateBankSysConf,
  updateAutoTransferStatus,
  bankMachineUpdate,
  csCompanyProfileSB,
  csUpdateCompanyProfileSB,
  transferFund,
  reloadBankService,
  requestBankStatement,
  csRejectBankAccount,
  csUpdateWalletBalance,
  csUpdateUplineReferral,
  csTopupReceiptList, //used for deposit with receipt without bank transaction
  csCreateTopUpReceipt, //used for create deposit with receipt without bank transaction
  csApproveTopUpReceipt, //used for approve deposit with receipt without bank transaction
  csRejectTopUpReceipt, //used for reject deposit with receipt without bank transaction
  mktgFBCampaignList,
  mktgFBCampaignNew,
  mktgFBCampaignUpdate,
  mktgFBEventList,
  reportWinlose,
  reportShare,
  reportShareDetail, //used for Downline report
  reportShareHolder,
  reportCommission,
  userMemberList,
  receiptGetList,
  receiptGetListV2,
  receiptFormNew,
  receiptFormNewV2,
  receiptFormNewV3,
  csCreateManualDeposit,
  puppetTransactionList,
  puppetTrxnList,
  puppetTransactionListBankTrxn,
  puppetTransactionListBankAppTrxn,
  puppetTransactionListBankAppTrxnV5,
  puppetTransactionListBankAppTrxnV6,
  clearBankTransaction,
  puppetTrxnDetails,
  uploadReceipt,
  uploadReceiptV2,
  uploadReceiptV3, //used for upload topup receipt (deposit with receipt without bank transaction)
  msgBroadcastPushnotify,
  msgBroadcastList,
  companyProfile,
  companyProfileUpdate,
  companyReport,
  csCommWitList,
  userProfileBank,
  userBankAll,
  userBankDelete,
  userBankNew,
  userBankUpdate,
  userWalletHistory,
  reportOperation,
  reportRedPacketSummary,
  reportBankBalance,
  reportCommBalance,
  reportDashMonitor,
  reportDownline,
  getReqToken,
  newsList,
  newsUpdate,
  newsDelete,
  newsNew,
  reportAccRecon,
  reportAccReconV2,
  reportAccReconTransferList,
  reportAccReconUpdate,
  reportAccReconBankList,
  reportAccReconBankListV2,
  reportAccReconDetails,
  reportAccReconTrxnList,
  reportBankStatement,
  reportBankStatementV2,
  reportApproveTrxn,
  reportApproveTrxnV3,
  reportDailySales,
  reportMasterData,
  reportDailyTrxnStats,
  reportVipPlayer,
  reportCustomerDep,
  reportDailyBankStats,
  reportDailyBankTrxnStats,
  reportTopList,
  reportTopTransactionSummary,
  reportGameBettingSummary,
  reportDepWthList,
  reportPlayer,
  reportPlayerV2,
  reportPlayerV3,
  reportPlayerV4,
  reportPlayerShareholder,
  reportPlayerShareholderV2,
  reportPlayerShareholderV3,
  reportAgentList,
  reportAgentData,
  reportMarketingData,
  reportFortuneWheel,
  reportGameStats,
  reportGameID,
  reportSmsCosting,
  // ReportDepSystemVsManual,
  reportShareholderDownline,
  reportShareholderV2,
  reportShareholderDetailsV2,
  reportDepositLedger,
  reportRegisterVsDeposit,
  getUserBankInfo,
  recoverWallet,
  movieList,
  getMovieById,
  movieNew,
  movieDelete,
  adultMovieList,
  getAdultMovieById,
  adultMovieNew,
  adultMovieDelete,
  newsModalNew,
  newsModalRecord,
  newsModaldetail,
  opModPreview,
  opModuleList,
  opModuleListFilter,
  opModuleLastListFilter,
  opModuleNew,
  opModAssignCS,
  getCSList,
  getLatestMasterFile,
  getBotStat,
  getBotStatV2,
  updateBotStat,
  reloadBot,
  getGameProviderList,
  updateGameProvider,
  requestToken,
  addBankAcc,
  removeBankAcc,
  reportAgentListV2,
  reportAgentDataV2,
  addBankAccV2,
  updateBankAccount,
  removeBankAccV2,

  // General Function
  getBankList,
  getCompanyProfileData,
  verifyValidMobile,
  agentTransferCredit,
  memberTransferCredit,
  getUserWalletBal,
  depositList,
  getMemberTransactionData,
  getUserData,
  createSystemUserV2,
  checkUsernameRegisteredV2,
  otpCheck,
  getCurrentWalletBalance,
  getBankBalanceWithAPI,
  getBankBalanceWithAPIV2,
  getBankBalanceWithAPIV3,
  adjustCredit,
  getPuppetJobList,
  getGameGroupPrefix,
  csRetrieveTwalletAcc,
  csAddTwalletAcc,
  csUpdateTwalletAcc,
  getEnvayaConfigList,
  getDepEmailList,
  getEmailAuthUrl,
  addDepEmail,
  removeEmailConfig,
  removeDepEmail,
  csCreateEnvayaConfig,
  csRemoveEnvayaConfig,
  getSettlementRecord,
  postSettlementRecord,
  getBankListV2,
  getBoUserType,
  getUserCredential,
  changeGameID,

  // Withdraw Management
  switchWithdrawMode,
  csUpdatePuppetJobStatus,
  reprocessWithdrawV2,

  //Company Comm Setting Profile
  getCommSettingProfile,
  updateCommSettingProfile,
  getUserCommSharePT,
  getPromotionData,
  updatePromotionSetting,
  getFwSetting,
  updateFwSetting,

  // SEO,
  pageSettingList,
  pageSettingListUpdate,

  // Reset Password
  checkResetPasswordDetail,
  updateResetPasswordDetail,

  // Game
  getSevenGameList,
  bindSevenGameUser,

  //Domain Setting
  getDomainList,
  createDomain,
  updateDomainStatus,
  updatePrimaryDomain,
  getDomainStatusDB,
  getServerIp,

  // SCB Connect
  createScbConnectInstance,
  getScbConnectOtp,
  getScbConnectConfigList,
  delScbConnectConfigRecord,
  delScbConnectInstance,

  //modify color
  updateCssVariables,
  saveOrupdateCssDraft,
  modifyColorPageLoad,
  deleteCssDraft,

  //Deposit receipt
  getDepositByReceiptList,
  rejectDepositByReceipt,
  acceptDepositByReceipt,
  autoFilledByReceipt,

  //Demo User
  getDemoUsers,
  getDemoUsersByMobile,
  createDemoUser,
  getDemoUserCompProfSett,

  //Onyx
  updateWithdrawBalanceOnyx,
  getLowestBalanceOnyx,

  //Page Access
  getPageAccess,
  getBoPage,
  deletePageAccess,
  setPageAccess,
  getParentPage,
  setUserAclStatus,
  setUserAcl,
  setUserAccessTemplate,
  setUserStatus,

  // OCR Transaction
  extractTransactionLabel,

  //clear cloudfare cache
  clearCloudfareCache,

  modifierUploadImage,
  modifierImageApply,
  checkModifierImageInProgress,
  startModifierImageProgress,
  modifierImageSaveDraft,
  getModifierImageDraftValue,
  getModifierImageDraftInfo,
};

//const {REACT_APP_DOMAIN, REACT_APP_UPLOAD_DOMAIN} = process.env;

export const version = "1.0.5";

//#region ---------- AWS ----------
//export let domain = "https://www.2f826cab.com/api/v2/backend";
//#endregion

//#region ---------- TERA ----------
//export let domain = "https://www.5a283031.com/api/v2/rest";
// export let domain = "https://www.2b6ec8df.com/api/v2/backend";
//export let fileDomain = "https://www.5a283031.com/public/receipt/";
//#endregion

export const methods = {
  get: "GET",
  post: "POST",
  update: "UPDATE",
  delete: "DELETE",
};

export const headers = {
  "Content-Type": "application/json",
};

export const actions = {
  //login
  login: "backend_login_verify",
  loginToken: "get_awt_token",
  loginTokenCheck: "login_token_check",

  //root
  userCompanyNew: "user_company_new",
  userCompanyList: "user_company_list",
  userManageUpdate: "user_manage_update",
  userManageUpdateV2: "user_manage_update_v2",
  userBankList: "user_bank_list",

  //company
  userShareholderNew: "user_shareholder_new",
  userShareholderList: "user_shareholder_list",
  userShareholderListV2: "user_shareholder_list_v2",
  userCSNew: "user_cs_new",
  userCSList: "user_cs_list",
  bankManageList: "bank_manage_list",
  bankManageNew: "bank_manage_new",
  bankManageUpdate: "bank_manage_update",
  reportWinlose: "report_winlose",
  companyProfile: "company_profile",
  companyProfileUpdate: "company_profile_update",
  companyReport: "report_shareholder_bycompany",

  //shareholder
  userDownlineNew: "user_downline_new",
  userAgentNew: "user_agent_new",
  userAgentList: "user_agent_list",
  userAgentListV2: "user_agent_list_v2",
  reportShare: "report_share",
  reportShareHolder: "report_shareholder",
  reportShareDetail: "report_share_detail",
  reportOperation: "report_transaction_byday",
  reportBankBalance: "report_bankbalance_byday",
  reportCommBalance: "report_commission_byday",
  reportDashMonitor: "report_dashboard_monitor",
  reportDownline: "report_downline",

  //agent
  reportCommission: "report_commission",
  userMemberList: "user_member_list",

  //cs
  csWithdrawList: "cs_withdraw_list",
  csWithdrawListV2: "cs_withdraw_list_v2",
  csWithdrawListV3: "cs_withdraw_list_v3",
  csWithdrawListV4: "cs_withdraw_list_v4",
  getProcessingWithdrawList: "get_processing_withdraw_list",
  getUnconfirmPuppetList: "get_unconfirm_puppet_list",
  getUnconfirmPuppetListV2: "get_unconfirm_puppet_list_v2",
  reprocessUnconfirmTrxn: "reprocess_unconfirm_trxn",
  clearUnconfirmTrxn: "clear_unconfirm_trxn",
  rejectUnconfirmTrxn: "reject_unconfirm_trxn",
  getTrxnScreenshot: "get_trxn_screenshot",
  // csWithdrawTurnbal: "cs_withdraw_turnbal",
  csWithdrawApprove: "cs_withdraw_approve",
  csWithdrawApproveV2: "cs_withdraw_approve_v2",
  csWithdrawApproveV3: "cs_withdraw_approve_v3",
  csWithdrawApproveV4: "cs_withdraw_approve_v4",
  csWithdrawManualApprove: "cs_withdraw_manual_approve",
  csWithdrawManualApproveV2: "cs_withdraw_manual_approve_v2",
  csWithdrawManualApproveV3: "cs_withdraw_manual_approve_v3",
  csWithdrawReject: "cs_withdraw_reject",
  csWithdrawRejectV2: "cs_withdraw_reject_v2",
  csWithdrawRejectV3: "cs_withdraw_reject_v3",
  csWithdrawRejectV4: "cs_withdraw_reject_v4",
  csWithdrawRejectV5: "cs_withdraw_reject_v5",
  csWithdrawAccept: "cs_withdraw_accept",
  csWithdrawComplete: "cs_withdraw_complete",
  csDepositList: "cs_deposit_list",
  csDepositListV1: "cs_deposit_list_v1",
  csDepositApprove: "cs_deposit_approve_v3",
  csDepositManualApprove: "cs_deposit_manual_approve",
  csBankConflictApprove: "cs_deposit_bankConflict_approve",
  csBankConflictApproveBankApp: "cs_deposit_bankCn_apprv2",
  csBankConflictApproveBankAppV3: "cs_deposit_bankCn_apprv3",
  csBankConflictApproveBankAppV4: "cs_deposit_bankCn_apprv4",
  csBankConflictApproveBankAppV5: "cs_deposit_bankCn_apprv5",
  csBankConflictApproveBankAppV6: "cs_deposit_bankCn_apprv6",
  csUpdateBankAccName: "cs_update_bank_acc_name",
  csResolveUnclaimDeposit: "cs_resolve_unclaim_deposit",
  // csBankConflictApproveBankApp : "cs_deposit_bankConflict_bankapp_approve",
  csDepositReject: "cs_deposit_reject_v1",
  csDepositFollowupUpdate: "cs_deposit_remark_update",
  csDepositAdjustNew: "cs_deposit_adjustment",
  csDownlineTopupList: "cs_deposit_downline_list",
  csDownlineTopupListSummary: "cs_deposit_downline_summary",
  csDownlineTopupUpdate: "cs_deposit_downline_update",
  csMemberList: "cs_member_list",
  csMemberListV2: "cs_member_list_v2",
  csMemberListV3: "cs_member_list_v3",
  csMemberListAcctnum: "cs_member_list_acctnum",
  csMemberListAcctnumV2: "cs_member_list_acctnum_v2",
  csMemberListGameId: "cs_member_list_game_id",
  userMemberLock: "user_member_lock",
  userMemberActive: "user_member_active",
  userMemberInactive: "user_member_inactive",
  userMemberLockV2: "user_member_lock_v2",
  userMemberActiveV2: "user_member_active_v2",
  userMemberInactiveV2: "user_member_inactive_v2",
  userDLSummary: "user_downline_summary",
  csWithdrawBank: "cs_withdraw_bank",
  csBankList: "cs_bank_list",
  csWithdrawCheck: "cs_withdraw_check",
  csWithdrawCheckV2: "cs_withdraw_check_v2",
  csWithdrawCheckV3: "cs_withdraw_check_v3",
  csCreateMember: "cs_create_member",
  csCreateMemberV2: "cs_create_member_v2",
  csCommWitList: "cs_commWit_list",
  csUpdateMember: "cs_member_update",
  csAvailBankList: "cs_avail_bank_list",
  csTransactionServiceList: "getTransactionServiceList",
  csVerifyMobile: "cs_member_mobile_verify",
  csCreateWithdrawal: "cs_wallet_withdraw_new_v3",
  csCreateWithdrawalV4: "wallet_withdraw_new_v4",
  csMemberWalletSummary: "cs_member_wallet_summary",
  csMemberWalletTrxn: "cs_member_wallet_trxn",
  csMemberWalletTrxnMobile: "cs_member_wallet_trxn_mobile",
  csMemberGameSummary: "cs_member_game_summary",
  csMemberGameTrxn: "cs_member_game_trxn",
  csWithdrawUpdate: "cs_withdraw_update",
  csWithdrawResortList: "cs_withdraw_resort_list",
  csWithdrawResortUpdate: "cs_withdraw_resort_update",
  reportRedPacketSummary: "report_angpao_summary",
  csMemberUpline: "user_cs_upline",
  csMemberDownline: "findDL",
  csMaintenanceList: "cs_maintenance_list_v2", // last updated on 2023-03-08
  csMaintenanceUpdate: "cs_maintenance_update_v2", // last updated on 2023-03-23
  csMemberFeedbackList: "cs_member_feedback_list",
  csMemberFeedbackUpdate: "cs_member_feedback_update",
  csMemberDownlineList: "user_cs_downline_list",
  csMonitorBankVerify: "cs_monitor_bank_verify",
  csMonitorDeposit: "cs_monitor_deposit_prev24H",
  csMonitorWithdraw: "cs_monitor_withdraw_prev24H",
  csMonitorWatchList: "cs_monitor_watchlist",
  bankMachineList: "bank_machine_list",
  getBankDetailList: "get_bank_detail_list",
  getBankMachList: "bank_machine_list",
  updateBankSysConf: "update_bank_sys_conf",
  updateAutoTransferStatus: "update_auto_transfer_status",
  bankMachineUpdate: "bank_machine_update",
  csCompanyProfileSB: "get_company_profile_sb",
  csUpdateCompanyProfileSB: "cs_update_company_profile_sb",
  transferFund: "transfer_fund",
  reloadBankService: "reload_bank_service",
  requestBankStatement: "request_bank_statement",
  csRejectBankAccount: "cs_reject_bank_account",
  csUpdateWalletBalance: "cs_update_wallet_balance",
  csUpdateUplineReferral: "cs_update_upline_referral",
  csRetrieveTwalletAcc: "cs_retrieve_twallet_acc",
  csAddTwalletAcc: "cs_add_twallet_acc",
  csUpdateTwalletAcc: "cs_update_twallet_acc",
  getEnvayaConfigList: "get_envaya_config_list",
  getDepEmailList: "get_dep_email_list",
  getEmailAuthUrl: "get_email_auth_url",
  addDepEmail: "add_dep_email",
  removeEmailConfig: "remove_email_config",
  removeDepEmail: "remove_dep_email",
  csCreateEnvayaConfig: "cs_create_envaya_config",
  csRemoveEnvayaConfig: "cs_remove_envaya_config",
  addBankAcc: "add_bank_acc",
  removeBankAcc: "remove_bank_acc",
  addBankAccV2: "add_bank_acc_v2",
  updateBankAccount: "update_bank_account_v2",
  removeBankAccV2: "remove_bank_acc_v2",

  //marketing
  mktgFBCampaignList: "mktg_fbpixel_list",
  mktgFBCampaignNew: "mktg_fbpixel_new",
  mktgFBCampaignUpdate: "mktg_fbpixel_update",
  mktgFBEventList: "mktg_fbevent_list",

  //receipt
  receiptGetList: "receipt_get_list",
  receiptGetListV2: "receipt_get_list_v2",
  receiptFormNew: "receipt_form_new",
  receiptFormNewV2: "receipt_form_new_v2",
  receiptFormNewV3: "receipt_form_new_v3",
  csCreateManualDeposit: "cs_create_manual_deposit",
  receiptTopupFile: "receipt_topup_file",

  //puppet
  puppetTransactionList: "puppet_transaction_list_v2",
  puppetTrxnList: "puppet_trxn_list",
  puppetTrxnDetails: "puppet_trxn_details",
  puppetTransactionListBankTrxn: "puppet_transaction_list_bank_trxn",
  puppetTransactionListBankAppTrxn: "puppet_transaction_list_bank_trxn_v3",
  puppetTransactionListBankAppTrxnV5: "puppet_transaction_list_bank_trxn_v5",
  puppetTransactionListBankAppTrxnV6: "puppet_transaction_list_bank_trxn_v6",
  clearBankTransaction: "clear_bank_transaction",
  //puppetTransactionListBankAppTrxn:"puppet_transaction_list_bank_trxn_v2",

  //notification
  msgBroadcastPushnotify: "msg_broadcast_pushnotify",
  msgBroadcastList: "msg_broadcast_list",

  //user
  userProfileBank: "user_profile_bank", //User Profile with bank details
  userBankAll: "user_bank_list_all",
  userBankDelete: "user_bank_delete",
  //userBankNew:"user_bank_new",
  userBankNew: "user_cs_bank_new",
  userBankUpdate: "user_cs_bank_update",
  userWalletHistory: "user_wallet_history",
  recoverWallet: "user_wallet_recover", //Recover Wallet

  //News
  newsList: "news_list",
  newsUpdate: "news_update",
  newsNew: "news_new",
  newsDelete: "news_delete",

  //Report
  reportAccRecon: "report_account_recon",
  reportAccReconV2: "report_account_recon_v2",
  reportAccReconUpdate: "report_account_recon_transfer_update",
  reportAccReconTransferList: "report_account_recon_transfer_list",
  reportAccReconBankList: "report_account_recon_transfer_bank_list",
  reportAccReconBankListV2: "report_account_recon_transfer_bank_listV2",
  reportAccReconDetails: "report_account_recon_details",
  reportAccReconTrxnList: "report_account_recon_trxn_list",
  reportBankStatement: "report_bank_statement",
  reportBankStatementV2: "report_bank_statementV2",
  reportApproveTrxn: "report_appr_trxnV2",
  reportApproveTrxnV3: "report_appr_trxnV3",
  reportDailySales: "report_daily_sales",
  reportMasterData: "report_master_data",
  reportDailyTrxnStats: "report_daily_trxn_stats",
  reportVipPlayer: "report_vip_player",
  reportCustomerDep: "report_customer_dep",
  reportDailyBankStats: "report_daily_bank_stats",
  reportDailyBankTrxnStats: "report_daily_bank_trxn_stats",
  reportTopList: "report_top_list",
  reportTopTransactionSummary: "report_top_transaction_summary",
  reportGameBettingSummary: "report_game_betting_summary",
  reportDepWthList: "report_dep_wth_list",
  reportPlayer: "report_player",
  reportPlayerV2: "report_player_v2",
  reportPlayerV3: "report_player_v3",
  reportPlayerV4: "report_player_v4",
  reportPlayerShareholder: "report_player_shareholder",
  reportPlayerShareholderV2: "report_player_shareholder_v2",
  reportPlayerShareholderV3: "report_player_shareholder_v3",
  reportShareholderConfiguration: "report_shareholder_configuration",
  reportAgentList: "report_agent_list",
  reportAgentData: "report_agent_data",
  reportMarketingData: "report_marketing_data",
  reportFortuneWheel: "report_fortune_wheel",
  reportGameStats: "report_game_stats",
  reportGameID: "report_game_id",
  reportSmsCosting: "report_sms_bymonth",
  // reportDepSystemVsManual : "report_dep_system_vs_manual",
  reportShareholderDownline: "report_shareholder_downline",
  reportShareholderV2: "report_shareholder_V2",
  reportShareholderDetailsV2: "report_shareholder_details_V2",
  reportDepositLedger: "report_deposit_ledger",
  reportRegisterVsDeposit: "report_register_vs_deposit",
  getUserBankInfo: "get_user_bank_info",
  reportAgentListV2: "report_agent_list_V2",
  reportAgentDataV2: "report_agent_data_V2",

  // movie
  movieList: "get_movie_record",
  getMovieById: "get_movie_by_id",
  movieNew: "movie_new",
  movieDelete: "movie_delete",

  // adult movie
  adultMovieList: "get_adult_movie_record",
  getAdultMovieById: "get_adult_movie_by_id",
  adultMovieNew: "insert_adult_movie_record",
  adultMovieDelete: "delete_adult_movie",

  // cs news_list
  newsModalRecord: "get_news_list_cs",
  newsModalNew: "cs_news_new",
  newsModalDetail: "get_news_list_cs_by_id",

  // Op module
  opModPreview: "filter_record_for_preview",
  opModuleList: "get_op_module_record",
  opModuleListFilter: "get_op_module_record_filter",
  opModuleLastListFilter: "get_op_module_last_list_filter",
  opModuleNew: "op_module_new",
  opModAssignCS: "assign_cs",
  getCSList: "get_cs_list",

  //Bot
  getBotStat: "getStatList",
  getBotStatV2: "getStatListV2",
  updateBotStat: "updateBotStatV2",
  reloadBot: "reloadBotV2",

  //Game Provider
  getGameProviderList: "cs_gameprovider_list",
  updateGameProvider: "cs_gameprovider_update",

  // Request Token
  requestToken: "request_token",

  //General Function
  getBankList: "get_bank_list",
  getCompanyProfileData: "get_company_profile_data",
  verifyValidMobile: "verify_valid_mobile",
  getUserWalletBal: "get_user_wallet_bal",
  depositList: "deposit_list_v2",
  getMemberTransactionData: "get_member_transaction_data",
  getUserData: "get_user_data",
  createSystemUserV2: "create_system_user_v2",
  checkUsernameRegisteredV2: "check_username_registered_v2",
  otpCheck: "otp_check",
  getCurrentWalletBalance: "get_current_wallet_balance",
  getBankBalanceWithAPI: "get_bank_balance_with_api",
  getBankBalanceWithAPIV2: "get_bank_balance_with_api_V2",
  getBankBalanceWithAPIV3: "get_bank_balance_with_api_V3",
  adjustCredit: "credit_adjustment",
  getPuppetJobList: "get_puppet_job_list",
  getGameGroupPrefix: "get_game_group_prefix",
  getSettlementRecord: "get_settlement_record",
  postSettlementRecord: "post_settlement_record",
  getBankListV2: "get_bank_list_v2",
  getBoUserType: "get_bo_user_type",
  getUserCredential: "get_user_credential",
  changeGameID: "change_game_id",
  

  //Withdraw Management
  switchWithdrawMode: "switch_withdrawal_mode",
  csUpdatePuppetJobStatus: "cs_update_puppet_job_status",
  reprocessWithdrawV2: "reprocess_withdraw_v2", // previous version: csUpdatePuppetJobStatus

  //misc
  getLatestMasterFile: "report_master_data_file",

  //Manual deposit (deposit with receipt & no bank transaction)
  csTopupReceiptList: "topup_receipt_list",
  csCreateTopUpReceipt: "topup_receipt",
  csApproveTopUpReceipt: "topup_receipt_approve",
  csRejectTopUpReceipt: "topup_receipt_reject",
  getDepositByReceiptList: "getDepositByReceiptList",
  rejectDepositByReceipt: "rejectDepositByReceipt",
  acceptDepositByReceipt: "acceptDepositByReceipt",
  autoFilledByReceipt: "autoFilledByReceipt",
  //Transfer Credit
  agentTransferCredit: "agent_transfer_credit",
  memberTransferCredit: "member_transfer_credit",

  //Company Comm Setting Profile
  getCommSettingProfile: "get_comm_setting_profile",
  updateCommSettingProfile: "update_comm_setting_profile",
  getUserCommSharePT: "get_user_comm_share_pt",

  //Company Promotion
  getPromotionData: "get_promotion_data",
  updatePromotionSetting: "update_promotion_setting",
  getFwSetting: "get_fw_setting",
  updateFwSetting: "update_fw_setting",

  //SEO
  pageSettingList: "page_setting_list",
  pageSettingListUpdate: "page_setting_list_update",

  //Reset Password
  checkResetPasswordDetail: "check_reset_password_detail",
  updateResetPasswordDetail: "update_reset_password_detail",

  //Game
  getSevenGameList: "get_seven_game_list",
  bindSevenGameUser: "bind_seven_game_user",
  getBindGameUserList: "get_bind_game_user_list",

  //Domain Setting
  getDomainList: "get_domain_list",
  createDomain: "create_domain",
  updateDomainStatus: "update_domain_status",
  updatePrimaryDomain: "update_primary_domain",
  getDomainStatusDB: "get_domain_status_db",
  getServerIp: "get_server_ip",

  // SCB Connect
  createScbConnectInstance: "create_scb_connect_instance",
  getScbConnectOtp: "get_scb_connect_otp",
  getScbConnectConfigList: "get_scb_connect_config_list",
  delScbConnectConfigRecord: "del_scb_connect_config_record",
  delScbConnectInstance: "del_scb_connect_instance",

  //Modify Color
  modifyColorPageLoad: "modifyColorPageLoad",
  deleteCssDraft: "delete_css_drafts",

  //Demo User
  getDemoUsers: "getDemoUsers",
  getDemoUsersByMobile: "getDemoUsersByMobile",
  createDemoUser: "createDemoUser",
  getDemoUserCompProfSett: "getDemoUserCompProfSett",

  //Onyx
  updateWithdrawBalanceOnyx: "updateWithdrawBalanceOnyx",
  getLowestBalanceOnyx: "getLowestBalanceOnyx",

  //Page Access
  getPageAccess: "get_page_access",
  getBoPage: "get_bo_page",
  deletePageAccess: "delete_page_access",
  setPageAccess: "set_page_access",
  getParentPage: "get_parent_page",
  setUserAclStatus: "set_user_acl_status",
  setUserAcl: "set_user_acl",
  setUserAccessTemplate: "set_user_access_template",
  setUserStatus: "set_user_status",

  // OCR Transaction
  extractTransactionLabel: "extract_transaction_label",

  //clear cloudfare cache
  clearCloudfareCache: "clear_cloudfare_cache",

  //modifier image
  modifierImageApply: "modifier_image_apply",
  checkModifierImageInProgress: "check_modifier_image_in_progress",
  startModifierImageProgress: "start_modifier_image_progress",
  modifierImageSaveDraft: "modifier_image_save_draft",
  getModifierImageDraftValue: "get_modifier_image_draft_value",
  getModifierImageDraftInfo: "get_existed_modifier_image_draft",
};

export const getHeader = (bearerToken="") => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${bearerToken}`
  };
};

const isEncoded = true;

function GetBody(action, data) {
  let encrypt = data;

  if (isEncoded) {
    encrypt = Base64.encode(JSON.stringify(encrypt));
  }

  return JSON.stringify({
    act: action,
    data: encrypt,
  });
}

function GetBodyBot(action, data) {
  let encrypt = data;

  // if (isEncoded) {
  //   encrypt = Base64.encode(JSON.stringify(encrypt));
  // }

  return JSON.stringify({
    act: action,
    data: encrypt,
  });
}

async function Fetch(params) {
  const { action, data, method = methods.post } = params;
  const { userId, loginToken, bearerToken } = Store.getState().login;
  let loginData = { ...data, loginToken: loginToken, userID: userId };

  const options = {
    method: method,
    headers: getHeader(bearerToken),
    body: GetBody(action, data),
  };
  
  return LoginCheckFetch({ action: actions.loginTokenCheck, data: loginData })
    .then((response) => response.json())
    .then(async (json) => {
      if (json.status === "ok") {
        try {
            const fetchResult = await fetch(domain,options);
            const fetchResponse = await fetchResult.clone().json();
            if (fetchResponse.status == 'err' && fetchResponse.errcode == '1101') {
              window.localStorage.clear();
              window.location.href = logout[0].path;
              return alert("Session expired ! please login again !");
            } else {
              return fetchResult;
            }
        } catch (e) {
          return e;
        }
      } else {
        window.localStorage.clear();
        window.location.href = logout[0].path;
        return alert("Session expired ! please login again !");
      }
    });
}

async function LoginCheckFetch(params) {
  const { action, data, method = methods.post } = params;
  const { bearerToken } = Store.getState().login;

  const options = {
    method: method,
    headers: getHeader(bearerToken),
    body: GetBody(action, data),
  };

  try {
    return await fetch(domain, options);
  } catch (e) {
    return e;
  }
}

async function FetchBot(params) {
  const { action, data, method = methods.post } = params;
  const { bearerToken } = Store.getState().login;

  const options = {
    method: method,
    headers: getHeader(bearerToken),
    body: GetBodyBot(action, data),
  };

  try {
    return await fetch(botDomain, options);
  } catch (e) {
    return e;
  }
}

async function loginTokenCheck(data) {
  return Fetch({ action: actions.loginTokenCheck, data: data });
}

async function backendLoginVerify(data) {
  return LoginCheckFetch({ action: actions.login, data: data });
}

async function backendLoginToken(data) {
  return Fetch({ action: actions.loginToken, data: data });
}

async function userCompanyNew(data) {
  return Fetch({ action: actions.userCompanyNew, data: data });
}

async function userCompanyList(data) {
  return Fetch({ action: actions.userCompanyList, data: data });
}

async function userManageUpdate(data) {
  return Fetch({ action: actions.userManageUpdate, data: data });
}

async function userManageUpdateV2(data) {
  return Fetch({ action: actions.userManageUpdateV2, data: data });
}

async function userBankList(data) {
  return Fetch({ action: actions.userBankList, data: data });
}

async function userShareholderNew(data) {
  return Fetch({ action: actions.userShareholderNew, data: data });
}

async function userShareholderList(data) {
  return Fetch({ action: actions.userShareholderList, data: data });
}

async function userShareholderListV2(data) {
  return Fetch({ action: actions.userShareholderListV2, data: data });
}

async function userDownlineNew(data) {
  return Fetch({ action: actions.userDownlineNew, data: data });
}

async function userAgentList(data) {
  return Fetch({ action: actions.userAgentList, data: data });
}

async function userAgentListV2(data) {
  return Fetch({ action: actions.userAgentListV2, data: data });
}

async function userAgentNew(data) {
  return Fetch({ action: actions.userAgentNew, data: data });
}

async function userCSNew(data) {
  return Fetch({ action: actions.userCSNew, data: data });
}

async function userCSList(data) {
  return Fetch({ action: actions.userCSList, data: data });
}

async function csWithdrawList(data) {
  return Fetch({ action: actions.csWithdrawList, data: data });
}

async function csWithdrawListV2(data) {
  return Fetch({ action: actions.csWithdrawListV2, data: data });
}

async function csWithdrawListV3(data) {
  return Fetch({ action: actions.csWithdrawListV3, data: data });
}

async function csWithdrawListV4(data) {
  return Fetch({ action: actions.csWithdrawListV4, data: data });
}

async function getProcessingWithdrawList(data) {
  return Fetch({ action: actions.getProcessingWithdrawList, data: data });
}

async function getUnconfirmPuppetList(data) {
  return Fetch({ action: actions.getUnconfirmPuppetList, data: data });
}

async function getUnconfirmPuppetListV2(data) {
  return Fetch({ action: actions.getUnconfirmPuppetListV2, data: data });
}

async function reprocessUnconfirmTrxn(data) {
  return Fetch({ action: actions.reprocessUnconfirmTrxn, data: data });
}

async function clearUnconfirmTrxn(data) {
  return Fetch({ action: actions.clearUnconfirmTrxn, data: data });
}

async function rejectUnconfirmTrxn(data) {
  return Fetch({ action: actions.rejectUnconfirmTrxn, data: data });
}

async function getTrxnScreenshot(data) {
  return Fetch({ action: actions.getTrxnScreenshot, data: data });
}

async function csWithdrawCheck(data) {
  return Fetch({ action: actions.csWithdrawCheck, data: data });
}

async function csWithdrawCheckV2(data) {
  return Fetch({ action: actions.csWithdrawCheckV2, data: data });
}

async function csWithdrawCheckV3(data) {
  return Fetch({ action: actions.csWithdrawCheckV3, data: data });
}

async function csWithdrawApprove(data) {
  return Fetch({ action: actions.csWithdrawApprove, data: data });
}

async function csWithdrawApproveV2(data) {
  return Fetch({ action: actions.csWithdrawApproveV2, data: data });
}

async function csWithdrawApproveV3(data) {
  return Fetch({ action: actions.csWithdrawApproveV3, data: data });
}

async function csWithdrawApproveV4(data) {
  return Fetch({ action: actions.csWithdrawApproveV4, data: data });
}

async function csWithdrawManualApprove(data) {
  return Fetch({ action: actions.csWithdrawManualApprove, data: data });
}

async function csWithdrawManualApproveV2(data) {
  return Fetch({ action: actions.csWithdrawManualApproveV2, data: data });
}

async function csWithdrawManualApproveV3(data) {
  return Fetch({ action: actions.csWithdrawManualApproveV3, data: data });
}

async function csWithdrawReject(data) {
  return Fetch({ action: actions.csWithdrawReject, data: data });
}

async function csWithdrawRejectV2(data) {
  return Fetch({ action: actions.csWithdrawRejectV2, data: data });
}

async function csWithdrawRejectV3(data) {
  return Fetch({ action: actions.csWithdrawRejectV3, data: data });
}

async function csWithdrawRejectV4(data) {
  return Fetch({ action: actions.csWithdrawRejectV4, data: data });
}

async function csWithdrawRejectV5(data) {
  return Fetch({ action: actions.csWithdrawRejectV5, data: data });
}

async function csWithdrawAccept(data) {
  return Fetch({ action: actions.csWithdrawAccept, data: data });
}

async function csWithdrawComplete(data) {
  return Fetch({ action: actions.csWithdrawComplete, data: data });
}

async function csCommWitList(data) {
  return Fetch({ action: actions.csCommWitList, data: data });
}

async function csDepositList(data) {
  return Fetch({ action: actions.csDepositList, data: data });
}

async function csDepositListV1(data) {
  return Fetch({ action: actions.csDepositListV1, data: data });
}

async function csDepositApprove(data) {
  return Fetch({ action: actions.csDepositApprove, data: data });
}

async function csDepositManualApprove(data) {
  return Fetch({ action: actions.csDepositManualApprove, data: data });
}

async function csBankConflictApprove(data) {
  return Fetch({ action: actions.csBankConflictApprove, data: data });
}

async function csBankConflictApproveBankApp(data) {
  return Fetch({ action: actions.csBankConflictApproveBankApp, data: data });
}

async function csBankConflictApproveBankAppV3(data) {
  return Fetch({ action: actions.csBankConflictApproveBankAppV3, data: data });
}

async function csBankConflictApproveBankAppV4(data) {
  return Fetch({ action: actions.csBankConflictApproveBankAppV4, data: data });
}

async function csBankConflictApproveBankAppV5(data) {
  return Fetch({ action: actions.csBankConflictApproveBankAppV5, data: data });
}

async function csBankConflictApproveBankAppV6(data) {
  return Fetch({ action: actions.csBankConflictApproveBankAppV6, data: data });
}

async function csUpdateBankAccName(data) {
  return Fetch({ action: actions.csUpdateBankAccName, data: data });
}

async function csResolveUnclaimDeposit(data) {
  return Fetch({ action: actions.csResolveUnclaimDeposit, data: data });
}

async function csDepositReject(data) {
  return Fetch({ action: actions.csDepositReject, data: data });
}

async function csDepositFollowupUpdate(data) {
  return Fetch({ action: actions.csDepositFollowupUpdate, data: data });
}

async function csDepositAdjustNew(data) {
  return Fetch({ action: actions.csDepositAdjustNew, data: data });
}

async function csDownlineTopupList(data) {
  return Fetch({ action: actions.csDownlineTopupList, data: data });
}

async function csDownlineTopupListSummary(data) {
  return Fetch({ action: actions.csDownlineTopupListSummary, data: data });
}

async function csDownlineTopupUpdate(data) {
  return Fetch({ action: actions.csDownlineTopupUpdate, data: data });
}

async function csMemberList(data) {
  return Fetch({ action: actions.csMemberList, data: data });
}

async function csMemberListV2(data) {
  return Fetch({ action: actions.csMemberListV2, data: data });
}

async function csMemberListV3(data) {
  return Fetch({ action: actions.csMemberListV3, data: data });
}

async function csMemberListAcctnum(data) {
  return Fetch({ action: actions.csMemberListAcctnum, data: data });
}

async function csMemberListAcctnumV2(data) {
  return Fetch({ action: actions.csMemberListAcctnumV2, data: data });
}

async function csMemberListGameId(data) {
  return Fetch({ action: actions.csMemberListGameId, data: data });
}

async function csCreateMember(data) {
  return Fetch({ action: actions.csCreateMember, data: data });
}

async function csCreateMemberV2(data) {
  return Fetch({ action: actions.csCreateMemberV2, data: data });
}

async function csUpdateMember(data) {
  return Fetch({ action: actions.csUpdateMember, data: data });
}

async function userMemberLock(data) {
  return Fetch({ action: actions.userMemberLock, data: data });
}

async function userMemberActive(data) {
  return Fetch({ action: actions.userMemberActive, data: data });
}

async function userMemberInactive(data) {
  return Fetch({ action: actions.userMemberInactive, data: data });
}
async function userMemberLockV2(data) {
  return Fetch({ action: actions.userMemberLockV2, data: data });
}

async function userMemberActiveV2(data) {
  return Fetch({ action: actions.userMemberActiveV2, data: data });
}

async function userMemberInactiveV2(data) {
  return Fetch({ action: actions.userMemberInactiveV2, data: data });
}

async function userDLSummary(data) {
  return Fetch({ action: actions.userDLSummary, data: data });
}

async function bankManageList(data) {
  return Fetch({ action: actions.bankManageList, data: data });
}

async function bankManageNew(data) {
  return Fetch({ action: actions.bankManageNew, data: data });
}

async function bankManageUpdate(data) {
  return Fetch({ action: actions.bankManageUpdate, data: data });
}

async function csWithdrawBank(data) {
  return Fetch({ action: actions.csWithdrawBank, data: data });
}

async function csBankList(data) {
  return Fetch({ action: actions.csBankList, data: data });
}

async function csAvailBankList(data) {
  return Fetch({ action: actions.csAvailBankList, data: data });
}

async function csTransactionServiceList(data) {
  return Fetch({ action: actions.csTransactionServiceList, data:data });
}

async function csVerifyMobile(data) {
  return Fetch({ action: actions.csVerifyMobile, data: data });
}

async function csCreateWithdrawal(data) {
  return Fetch({ action: actions.csCreateWithdrawal, data: data });
}

async function csCreateWithdrawalV4(data) {
  return Fetch({ action: actions.csCreateWithdrawalV4, data: data });
}

async function csMemberWalletSummary(data) {
  return Fetch({ action: actions.csMemberWalletSummary, data: data });
}

async function csMemberWalletTrxn(data) {
  return Fetch({ action: actions.csMemberWalletTrxn, data: data });
}

async function csMemberWalletTrxnMobile(data) {
  return Fetch({ action: actions.csMemberWalletTrxnMobile, data: data });
}

async function csMemberGameSummary(data) {
  return Fetch({ action: actions.csMemberGameSummary, data: data });
}

async function csMemberGameTrxn(data) {
  return Fetch({ action: actions.csMemberGameTrxn, data: data });
}

async function csWithdrawUpdate(data) {
  return Fetch({ action: actions.csWithdrawUpdate, data: data });
}

async function csWithdrawResortList(data) {
  return Fetch({ action: actions.csWithdrawResortList, data: data });
}

async function csWithdrawResortUpdate(data) {
  return Fetch({ action: actions.csWithdrawResortUpdate, data: data });
}

async function csMemberUpline(data) {
  return Fetch({ action: actions.csMemberUpline, data: data });
}

async function csMemberDownline(data) {
  return Fetch({ action: actions.csMemberDownline, data: data });
}

async function csMaintenanceList(data) {
  return Fetch({ action: actions.csMaintenanceList, data: data });
}

async function csMaintenanceUpdate(data) {
  return Fetch({ action: actions.csMaintenanceUpdate, data: data });
}

async function csMemberFeedbackList(data) {
  return Fetch({ action: actions.csMemberFeedbackList, data: data });
}

async function csMemberFeedbackUpdate(data) {
  return Fetch({ action: actions.csMemberFeedbackUpdate, data: data });
}

async function csMemberDownlineList(data) {
  return Fetch({ action: actions.csMemberDownlineList, data: data });
}

async function csMonitorBankVerify(data) {
  return Fetch({ action: actions.csMonitorBankVerify, data: data });
}

async function csMonitorDeposit(data) {
  return Fetch({ action: actions.csMonitorDeposit, data: data });
}

async function csMonitorWithdraw(data) {
  return Fetch({ action: actions.csMonitorWithdraw, data: data });
}

async function csMonitorWatchList(data) {
  return Fetch({ action: actions.csMonitorWatchList, data: data });
}

async function bankMachineList(data) {
  return Fetch({ action: actions.bankMachineList, data: data });
}

async function getBankDetailList(data) {
  return Fetch({ action: actions.getBankDetailList, data: data });
}

async function getBankMachList(data) {
  return Fetch({ action: actions.getBankMachList, data: data });
}

async function updateBankSysConf(data) {
  return Fetch({ action: actions.updateBankSysConf, data: data });
}
async function updateAutoTransferStatus(data) {
  return Fetch({ action: actions.updateAutoTransferStatus, data: data });
}
async function bankMachineUpdate(data) {
  return Fetch({ action: actions.bankMachineUpdate, data: data });
}

async function mktgFBCampaignList(data) {
  return Fetch({ action: actions.mktgFBCampaignList, data: data });
}

async function mktgFBCampaignNew(data) {
  return Fetch({ action: actions.mktgFBCampaignNew, data: data });
}

async function mktgFBCampaignUpdate(data) {
  return Fetch({ action: actions.mktgFBCampaignUpdate, data: data });
}

async function mktgFBEventList(data) {
  return Fetch({ action: actions.mktgFBEventList, data: data });
}

async function reportOperation(data) {
  return Fetch({ action: actions.reportOperation, data: data });
}

async function reportShareholderConfiguration(data) {
  return Fetch({ action: actions.reportShareholderConfiguration, data: data });
}

async function reportShareholderDownline(data) {
  return Fetch({ action: actions.reportShareholderDownline, data: data });
}

async function reportShareholderV2(data) {
  return Fetch({ action: actions.reportShareholderV2, data: data });
}

async function reportShareholderDetailsV2(data) {
  return Fetch({ action: actions.reportShareholderDetailsV2, data: data });
}

async function reportDepositLedger(data) {
  return Fetch({ action: actions.reportDepositLedger, data: data });
}

async function reportRedPacketSummary(data) {
  return Fetch({ action: actions.reportRedPacketSummary, data: data });
}

async function reportBankBalance(data) {
  return Fetch({ action: actions.reportBankBalance, data: data });
}

async function reportCommBalance(data) {
  return Fetch({ action: actions.reportCommBalance, data: data });
}

async function reportDashMonitor(data) {
  return Fetch({ action: actions.reportDashMonitor, data: data });
}

async function reportDownline(data) {
  return Fetch({ action: actions.reportDownline, data: data });
}

async function reportWinlose(data) {
  return Fetch({ action: actions.reportWinlose, data: data });
}

async function reportShare(data) {
  return Fetch({ action: actions.reportShare, data: data });
}

async function reportShareHolder(data) {
  return Fetch({ action: actions.reportShareHolder, data: data });
}

async function reportShareDetail(data) {
  return Fetch({ action: actions.reportShareDetail, data: data });
}

async function reportCommission(data) {
  return Fetch({ action: actions.reportCommission, data: data });
}

async function reportDailySales(data) {
  return Fetch({ action: actions.reportDailySales, data: data });
}

async function reportMasterData(data) {
  return Fetch({ action: actions.reportMasterData, data: data });
}

async function reportDailyTrxnStats(data) {
  return Fetch({ action: actions.reportDailyTrxnStats, data: data });
}

async function reportVipPlayer(data) {
  return Fetch({ action: actions.reportVipPlayer, data: data });
}

async function reportCustomerDep(data) {
  return Fetch({ action: actions.reportCustomerDep, data: data });
}

async function reportDailyBankStats(data) {
  return Fetch({ action: actions.reportDailyBankStats, data: data });
}

async function reportDailyBankTrxnStats(data) {
  return Fetch({ action: actions.reportDailyBankTrxnStats, data: data });
}

async function reportTopList(data) {
  return Fetch({ action: actions.reportTopList, data: data });
}

async function reportTopTransactionSummary(data) {
  return Fetch({ action: actions.reportTopTransactionSummary, data: data });
}

async function reportGameBettingSummary(data) {
  return Fetch({ action: actions.reportGameBettingSummary, data: data });
}

async function reportDepWthList(data) {
  return Fetch({ action: actions.reportDepWthList, data: data });
}

async function reportPlayer(data) {
  return Fetch({ action: actions.reportPlayer, data: data });
}

async function reportPlayerV2(data) {
  return Fetch({ action: actions.reportPlayerV2, data: data });
}

async function reportPlayerV3(data) {
  return Fetch({ action: actions.reportPlayerV3, data: data });
}

async function reportPlayerV4(data) {
  return Fetch({ action: actions.reportPlayerV4, data: data });
}

async function reportPlayerShareholder(data) {
  return Fetch({ action: actions.reportPlayerShareholder, data: data });
}

async function reportPlayerShareholderV2(data) {
  return Fetch({ action: actions.reportPlayerShareholderV2, data: data });
}

async function reportPlayerShareholderV3(data) {
  return Fetch({ action: actions.reportPlayerShareholderV3, data: data });
}

async function reportAgentList(data) {
  return Fetch({ action: actions.reportAgentList, data: data });
}

async function reportAgentData(data) {
  return Fetch({ action: actions.reportAgentData, data: data });
}

async function reportMarketingData(data) {
  return Fetch({ action: actions.reportMarketingData, data: data });
}

async function reportFortuneWheel(data) {
  return Fetch({ action: actions.reportFortuneWheel, data: data });
}

async function reportGameStats(data) {
  return Fetch({ action: actions.reportGameStats, data: data });
}

async function reportGameID(data) {
  return Fetch({ action: actions.reportGameID, data: data });
}

async function reportSmsCosting(data) {
  return Fetch({ action: actions.reportSmsCosting, data: data });
}

// async function ReportDepSystemVsManual(data) {
//   return Fetch({ action: actions.reportDepSystemVsManual, data: data });
// }

async function userMemberList(data) {
  return Fetch({ action: actions.userMemberList, data: data });
}

async function receiptGetList(data) {
  return Fetch({ action: actions.receiptGetList, data: data });
}

async function receiptGetListV2(data) {
  return Fetch({ action: actions.receiptGetListV2, data: data });
}

async function receiptFormNew(data) {
  return Fetch({ action: actions.receiptFormNew, data: data });
}

async function receiptFormNewV2(data) {
  return Fetch({ action: actions.receiptFormNewV2, data: data });
}

async function receiptFormNewV3(data) {
  return Fetch({ action: actions.receiptFormNewV3, data: data });
}

async function csCreateManualDeposit(data) {
  return Fetch({ action: actions.csCreateManualDeposit, data: data });
}

async function puppetTransactionList(data) {
  return Fetch({ action: actions.puppetTransactionList, data: data });
}

async function puppetTrxnList(data) {
  return Fetch({ action: actions.puppetTrxnList, data: data });
}

async function puppetTrxnDetails(data) {
  return Fetch({ action: actions.puppetTrxnDetails, data: data });
}

async function puppetTransactionListBankTrxn(data) {
  return Fetch({ action: actions.puppetTransactionListBankTrxn, data: data });
}

async function puppetTransactionListBankAppTrxn(data) {
  return Fetch({
    action: actions.puppetTransactionListBankAppTrxn,
    data: data,
  });
}

async function puppetTransactionListBankAppTrxnV5(data) {
  return Fetch({
    action: actions.puppetTransactionListBankAppTrxnV5,
    data: data,
  });
}

async function puppetTransactionListBankAppTrxnV6(data) {
  return Fetch({
    action: actions.puppetTransactionListBankAppTrxnV6,
    data: data,
  });
}

async function clearBankTransaction(data) {
  return Fetch({ action: actions.clearBankTransaction, data: data });
}

async function msgBroadcastPushnotify(data) {
  return Fetch({ action: actions.msgBroadcastPushnotify, data: data });
}

async function msgBroadcastList(data) {
  return Fetch({ action: actions.msgBroadcastList, data: data });
}

async function companyProfile(data) {
  return Fetch({ action: actions.companyProfile, data: data });
}

async function companyProfileUpdate(data) {
  return Fetch({ action: actions.companyProfileUpdate, data: data });
}

async function companyReport(data) {
  return Fetch({ action: actions.companyReport, data: data });
}

async function userProfileBank(data) {
  return Fetch({ action: actions.userProfileBank, data: data });
}

async function userBankAll(data) {
  return Fetch({ action: actions.userBankAll, data: data });
}

async function userBankDelete(data) {
  return Fetch({ action: actions.userBankDelete, data: data });
}

async function userBankNew(data) {
  return Fetch({ action: actions.userBankNew, data: data });
}

async function userBankUpdate(data) {
  return Fetch({ action: actions.userBankUpdate, data: data });
}

async function userWalletHistory(data) {
  return Fetch({ action: actions.userWalletHistory, data: data });
}

async function newsList(data) {
  return Fetch({ action: actions.newsList, data: data });
}

async function newsUpdate(data) {
  return Fetch({ action: actions.newsUpdate, data: data });
}

async function newsDelete(data) {
  return Fetch({ action: actions.newsDelete, data: data });
}

async function newsNew(data) {
  return Fetch({ action: actions.newsNew, data: data });
}

async function reportAccRecon(data) {
  return Fetch({ action: actions.reportAccRecon, data: data });
}

async function reportAccReconV2(data) {
  return Fetch({ action: actions.reportAccReconV2, data: data });
}

async function reportAccReconUpdate(data) {
  return Fetch({ action: actions.reportAccReconUpdate, data: data });
}

async function reportAccReconTransferList(data) {
  return Fetch({ action: actions.reportAccReconTransferList, data: data });
}

async function reportAccReconBankList(data) {
  return Fetch({ action: actions.reportAccReconBankList, data: data });
}

async function reportAccReconBankListV2(data) {
  return Fetch({ action: actions.reportAccReconBankListV2, data: data });
}

async function reportAccReconDetails(data) {
  return Fetch({ action: actions.reportAccReconDetails, data: data });
}

async function reportAccReconTrxnList(data) {
  return Fetch({ action: actions.reportAccReconTrxnList, data: data });
}

async function reportBankStatement(data) {
  return Fetch({ action: actions.reportBankStatement, data: data });
}

async function reportBankStatementV2(data) {
  return Fetch({ action: actions.reportBankStatementV2, data: data });
}

async function reportApproveTrxn(data) {
  return Fetch({ action: actions.reportApproveTrxn, data: data });
}

async function reportApproveTrxnV3(data) {
  return Fetch({ action: actions.reportApproveTrxnV3, data: data });
}

async function recoverWallet(data) {
  return Fetch({ action: actions.recoverWallet, data: data });
}

async function movieList(data) {
  return Fetch({ action: actions.movieList, data: data });
}

async function getMovieById(data) {
  return Fetch({ action: actions.getMovieById, data: data });
}

async function movieNew(data) {
  return Fetch({ action: actions.movieNew, data: data });
}

async function movieDelete(data) {
  return Fetch({ action: actions.movieDelete, data: data });
}

async function adultMovieList(data) {
  return Fetch({ action: actions.adultMovieList, data: data });
}

async function getAdultMovieById(data) {
  return Fetch({ action: actions.getAdultMovieById, data: data });
}

async function adultMovieNew(data) {
  return Fetch({ action: actions.adultMovieNew, data: data });
}

async function adultMovieDelete(data) {
  return Fetch({ action: actions.adultMovieDelete, data: data });
}

async function newsModalNew(data) {
  return await Fetch({ action: actions.newsModalNew, data: data });
}

async function newsModalRecord(data) {
  return await Fetch({ action: actions.newsModalRecord, data: data });
}

async function newsModaldetail(data) {
  return await Fetch({ action: actions.newsModalDetail, data: data });
}

async function opModPreview(data) {
  return await Fetch({ action: actions.opModPreview, data: data });
}

async function opModuleList(data) {
  return await Fetch({ action: actions.opModuleList, data: data });
}

async function opModuleListFilter(data) {
  return await Fetch({ action: actions.opModuleListFilter, data: data });
}

async function opModuleLastListFilter(data) {
  return await Fetch({ action: actions.opModuleLastListFilter, data: data });
}

async function opModuleNew(data) {
  return await Fetch({ action: actions.opModuleNew, data: data });
}

async function opModAssignCS(data) {
  return await Fetch({ action: actions.opModAssignCS, data: data });
}

async function getCSList(data) {
  return await Fetch({ action: actions.getCSList, data: data });
}

async function getLatestMasterFile(data) {
  return await Fetch({ action: actions.getLatestMasterFile, data: data });
}

async function getBotStat(data) {
  return await FetchBot({ action: actions.getBotStat, data: data });
}

async function getBotStatV2(data) {
  return await FetchBot({ action: actions.getBotStatV2, data: data });
}

async function updateBotStat(data) {
  return await FetchBot({ action: actions.updateBotStat, data: data });
}

async function reloadBot(data) {
  return await FetchBot({ action: actions.reloadBot, data: data });
}

async function getGameProviderList(data) {
  return await Fetch({ action: actions.getGameProviderList, data: data });
}

async function updateGameProvider(data) {
  return await Fetch({ action: actions.updateGameProvider, data: data });
}

async function requestToken(data) {
  return await Fetch({ action: actions.requestToken, data: data });
}

async function reportRegisterVsDeposit(data) {
  return await Fetch({ action: actions.reportRegisterVsDeposit, data: data });
}

async function getUserBankInfo(data) {
  return await Fetch({ action: actions.getUserBankInfo, data: data });
}

async function csCompanyProfileSB(data) {
  return await Fetch({ action: actions.csCompanyProfileSB, data: data });
}

async function csUpdateCompanyProfileSB(data) {
  return await Fetch({ action: actions.csUpdateCompanyProfileSB, data: data });
}

async function transferFund(data) {
  return await Fetch({ action: actions.transferFund, data: data });
}

async function reloadBankService(data) {
  return await Fetch({ action: actions.reloadBankService, data: data });
}

async function requestBankStatement(data) {
  return await Fetch({ action: actions.requestBankStatement, data: data });
}

async function csRejectBankAccount(data) {
  return await Fetch({ action: actions.csRejectBankAccount, data: data });
}

async function csUpdateWalletBalance(data) {
  return await Fetch({ action: actions.csUpdateWalletBalance, data: data });
}

async function csUpdateUplineReferral(data) {
  return await Fetch({ action: actions.csUpdateUplineReferral, data: data });
}

async function csTopupReceiptList(data) {
  return await Fetch({ action: actions.csTopupReceiptList, data: data });
}

async function csCreateTopUpReceipt(data) {
  return await Fetch({ action: actions.csCreateTopUpReceipt, data: data });
}

async function csApproveTopUpReceipt(data) {
  return await Fetch({ action: actions.csApproveTopUpReceipt, data: data });
}

async function csRejectTopUpReceipt(data) {
  return await Fetch({ action: actions.csRejectTopUpReceipt, data: data });
}

// General Function
async function getBankList(data) {
  return await Fetch({ action: actions.getBankList, data: data });
}

async function getCompanyProfileData(data) {
  return await Fetch({ action: actions.getCompanyProfileData, data: data });
}

async function verifyValidMobile(data) {
  return await Fetch({ action: actions.verifyValidMobile, data: data });
}

async function agentTransferCredit(data) {
  return await Fetch({ action: actions.agentTransferCredit, data: data });
}

async function memberTransferCredit(data) {
  return await Fetch({ action: actions.memberTransferCredit, data: data });
}

async function getUserWalletBal(data) {
  return await Fetch({ action: actions.getUserWalletBal, data: data });
}

async function depositList(data) {
  return await Fetch({ action: actions.depositList, data: data });
}

async function getMemberTransactionData(data) {
  return await Fetch({ action: actions.getMemberTransactionData, data: data });
}

async function getUserData(data) {
  return await Fetch({ action: actions.getUserData, data: data });
}

async function createSystemUserV2(data) {
  return await Fetch({ action: actions.createSystemUserV2, data: data });
}

async function checkUsernameRegisteredV2(data) {
  return await Fetch({ action: actions.checkUsernameRegisteredV2, data: data });
}

async function otpCheck(data) {
  return await Fetch({ action: actions.otpCheck, data: data });
}

async function getCurrentWalletBalance(data) {
  return Fetch({ action: actions.getCurrentWalletBalance, data: data });
}

async function getBankBalanceWithAPI(data) {
  return await Fetch({ action: actions.getBankBalanceWithAPI, data: data });
}

async function getBankBalanceWithAPIV2(data) {
  return await Fetch({ action: actions.getBankBalanceWithAPIV2, data: data });
}

async function getBankBalanceWithAPIV3(data) {
  return await Fetch({ action: actions.getBankBalanceWithAPIV3, data: data });
}

async function adjustCredit(data) {
  return await Fetch({ action: actions.adjustCredit, data: data });
}

async function getPuppetJobList(data) {
  return await Fetch({ action: actions.getPuppetJobList, data: data });
}

async function getGameGroupPrefix(data) {
  return Fetch({ action: actions.getGameGroupPrefix, data: data });
}

async function csRetrieveTwalletAcc(data) {
  return Fetch({ action: actions.csRetrieveTwalletAcc, data: data });
}

async function csAddTwalletAcc(data) {
  return Fetch({ action: actions.csAddTwalletAcc, data: data });
}

async function csUpdateTwalletAcc(data) {
  return Fetch({ action: actions.csUpdateTwalletAcc, data: data });
}

async function getEnvayaConfigList(data) {
  return Fetch({ action: actions.getEnvayaConfigList, data: data });
}

async function getDepEmailList() {
  return Fetch({ action: actions.getDepEmailList, data: {} });
}

async function getEmailAuthUrl(data) {
  return Fetch({ action: actions.getEmailAuthUrl, data: data });
}

async function addDepEmail(data) {
  return Fetch({ action: actions.addDepEmail, data: data });
}

async function removeEmailConfig(data) {
  return Fetch({ action: actions.removeEmailConfig, data: data });
}

async function removeDepEmail(data) {
  return Fetch({ action: actions.removeDepEmail, data: data });
}

async function csCreateEnvayaConfig(data) {
  return Fetch({ action: actions.csCreateEnvayaConfig, data: data });
}

async function csRemoveEnvayaConfig(data) {
  return Fetch({ action: actions.csRemoveEnvayaConfig, data: data });
}

async function getSettlementRecord(data) {
  return Fetch({ action: actions.getSettlementRecord, data: data });
}

async function postSettlementRecord(data) {
  return Fetch({ action: actions.postSettlementRecord, data: data });
}

async function getBankListV2(data) {
  return await Fetch({ action: actions.getBankListV2, data: data });
}

async function getBoUserType(data) {
  return await Fetch({ action: actions.getBoUserType, data: data });
}

async function getUserCredential(data) {
  return await Fetch({ action: actions.getUserCredential, data: data });
}

async function changeGameID(data) {
  return await Fetch({ action: actions.changeGameID, data: data });
}

async function addBankAcc(data) {
  return Fetch({ action: actions.addBankAcc, data: data });
}

// Withdraw Management
async function switchWithdrawMode(data) {
  return await Fetch({ action: actions.switchWithdrawMode, data: data });
}

async function csUpdatePuppetJobStatus(data) {
  return await Fetch({ action: actions.csUpdatePuppetJobStatus, data: data });
}

async function reprocessWithdrawV2(data) {
  return await Fetch({ action: actions.reprocessWithdrawV2, data: data });
}

// Company Comm Setting Profile
async function getCommSettingProfile(data) {
  return await Fetch({ action: actions.getCommSettingProfile, data: data });
}

async function updateCommSettingProfile(data) {
  return await Fetch({ action: actions.updateCommSettingProfile, data: data });
}

async function getUserCommSharePT(data) {
  return await Fetch({ action: actions.getUserCommSharePT, data: data });
}

async function getPromotionData(data) {
  return await Fetch({ action: actions.getPromotionData, data: data });
}

async function updatePromotionSetting(data) {
  return await Fetch({ action: actions.updatePromotionSetting, data: data });
}

async function getFwSetting(data) {
  return await Fetch({ action: actions.getFwSetting, data: data });
}

async function updateFwSetting(data) {
  return await Fetch({ action: actions.updateFwSetting, data: data });
}

// SEO
async function pageSettingList(data) {
  return Fetch({ action: actions.pageSettingList, data: data });
}

async function pageSettingListUpdate(data) {
  return Fetch({ action: actions.pageSettingListUpdate, data: data });
}

// Reset Password
async function checkResetPasswordDetail(data) {
  return Fetch({ action: actions.checkResetPasswordDetail, data: data });
}

async function updateResetPasswordDetail(data) {
  return Fetch({ action: actions.updateResetPasswordDetail, data: data });
}

// Game
async function getSevenGameList(data) {
  return Fetch({ action: actions.getSevenGameList, data: data });
}

async function bindSevenGameUser(data) {
  return Fetch({ action: actions.bindSevenGameUser, data: data });
}

async function getBindGameUserList(data) {
  return Fetch({ action: actions.getBindGameUserList, data: data });
}

//Domain Setting
async function getDomainList(data) {
  return await Fetch({ action: actions.getDomainList, data: data });
}

async function createDomain(data) {
  return await Fetch({ action: actions.createDomain, data: data });
}

async function updateDomainStatus(data) {
  return await Fetch({ action: actions.updateDomainStatus, data: data });
}

async function getDomainStatusDB(data) {
  return await Fetch({ action: actions.getDomainStatusDB, data: data });
}

async function getServerIp(data) {
  return await Fetch({ action: actions.getServerIp, data: data });
}

async function updatePrimaryDomain(data) {
  return await Fetch({ action: actions.updatePrimaryDomain, data: data });
}

// SCB Connect
async function getScbConnectServerList(data) {
  return await Fetch({ action: actions.getScbConnectServerList, data: data });
}

async function createScbConnectInstance(data) {
  return await Fetch({ action: actions.createScbConnectInstance, data: data });
}

async function getScbConnectOtp(data) {
  return await Fetch({ action: actions.getScbConnectOtp, data: data });
}

async function getScbConnectConfigList(data) {
  return await Fetch({ action: actions.getScbConnectConfigList, data: data });
}

async function delScbConnectConfigRecord(data) {
  return await Fetch({ action: actions.delScbConnectConfigRecord, data: data });
}

async function delScbConnectInstance(data) {
  return await Fetch({ action: actions.delScbConnectInstance, data: data });
}

async function getDepositByReceiptList() {
  return await Fetch({ action: actions.getDepositByReceiptList, data: {} });
}

async function rejectDepositByReceipt(data) {
  return await Fetch({ action: actions.rejectDepositByReceipt, data: data });
}
async function acceptDepositByReceipt(data) {
  return await Fetch({ action: actions.acceptDepositByReceipt, data: data });
}

async function autoFilledByReceipt(data) {
  return await Fetch({ action: actions.autoFilledByReceipt, data: data });
}

async function getDemoUsers() {
  return await Fetch({ action: actions.getDemoUsers, data: {} });
}

async function getDemoUsersByMobile(data) {
  return await Fetch({ action: actions.getDemoUsersByMobile, data: data });
}

async function createDemoUser(data) {
  return await Fetch({ action: actions.createDemoUser, data: data });
}

async function getDemoUserCompProfSett() {
  return await Fetch({ action: actions.getDemoUserCompProfSett, data: {} });
}

async function updateWithdrawBalanceOnyx(data) {
  return await Fetch({ action: actions.updateWithdrawBalanceOnyx, data: data });
}

async function getLowestBalanceOnyx() {
  return await Fetch({ action: actions.getLowestBalanceOnyx, data: {} });
}

async function getPageAccess(data) {
  return await Fetch({ action: actions.getPageAccess, data: data});
}

async function getBoPage() {
  return await Fetch({ action: actions.getBoPage, data: {}});
}

async function deletePageAccess(data) {
  return await Fetch({ action: actions.deletePageAccess, data: data});
}

async function setPageAccess(data) {
  return await Fetch({ action: actions.setPageAccess, data: data});
}

async function getParentPage() {
  return await Fetch({ action: actions.getParentPage, data: {}});
}

async function setUserAclStatus(data) {
  return await Fetch({ action: actions.setUserAclStatus, data: data});
}

async function setUserAcl(data) {
  return await Fetch({ action: actions.setUserAcl, data: data});
}

async function setUserAccessTemplate(data) {
  return await Fetch({ action: actions.setUserAccessTemplate, data: data});
}

async function setUserStatus(data) {
  return await Fetch({ action: actions.setUserStatus, data: data});
}

async function extractTransactionLabel(data) {
  return await Fetch({action: actions.extractTransactionLabel, data: data})
}

async function uploadReceipt(data) {
  return fetch("https://www.2b6ec8df.com/api/backend_topup/receipt", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}

async function uploadReceiptV2(data) {
  return fetch(uploadDomain, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}
// Used for topup receipt upload
async function uploadReceiptV3(data) {
  return fetch(topupReceiptUploadDomain, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}

function reqTokenFormat(date) {
  let getMilisecondMoment = moment(date).format("DD-MM-YYYY HH:mm:ss SSS");

  return getMilisecondMoment;
}

let lastTokenTime = "";
function getReqToken(type) {
  const now = new Date();
  const newToken = `BO-${reqTokenFormat(now)}-${type}`;
  const { reqToken } = Store.getState().login;
  //const {reqToken} = Store.getState().option;

  if (lastTokenTime === "") {
    lastTokenTime = now;
    return newToken;
  } else if (
    Math.floor((now.getTime() - lastTokenTime.getTime()) / 1000) >= reqToken
  ) {
    lastTokenTime = now;
    return newToken;
  }

  return `BO-${reqTokenFormat(lastTokenTime)}-${type}`;
}

async function removeBankAcc(data) {
  return await Fetch({ action: actions.removeBankAcc, data: data });
}

async function addBankAccV2(data) {
  return Fetch({ action: actions.addBankAccV2, data: data });
} 

async function updateBankAccount(data) {
  return Fetch({ action: actions.updateBankAccount, data: data });
}

async function removeBankAccV2(data) {
  return await Fetch({ action: actions.removeBankAccV2, data: data });
}

async function updateCssVariables(data) {
  return fetch(updateCssVariablesDomain, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}

async function saveOrupdateCssDraft(data) {
  return fetch(saveOrOpdateCssDraftDomain, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}

async function deleteCssDraft(data) {
  return await Fetch({
    action: actions.deleteCssDraft,
    data: data,
  });
}

async function modifyColorPageLoad() {
  return await Fetch({
    action: actions.modifyColorPageLoad,
    data: {},
  });
}

async function clearCloudfareCache() {
  return await Fetch({
    action: actions.clearCloudfareCache,
    data: {},
  });
}

async function modifierUploadImage(data) {
  return fetch(modifierUploadImageDomain, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  });
}

async function modifierImageApply(data) {
  return await Fetch({ action: actions.modifierImageApply, data: data});
}

async function checkModifierImageInProgress() {
  return await Fetch({ action: actions.checkModifierImageInProgress, data: {}});
}

async function startModifierImageProgress(data) {
  return await Fetch({ action: actions.startModifierImageProgress, data: data});
}

async function modifierImageSaveDraft(data) {
  return await Fetch({ action: actions.modifierImageSaveDraft, data: data});
}

async function getModifierImageDraftValue(data) {
  return await Fetch({ action: actions.getModifierImageDraftValue, data: data});
}

async function getModifierImageDraftInfo(data) {
  return await Fetch({ action: actions.getModifierImageDraftInfo, data: data});
}

async function reportAgentListV2(data) {
  return Fetch({ action: actions.reportAgentListV2, data: data });
}

async function reportAgentDataV2(data) {
  return Fetch({ action: actions.reportAgentDataV2, data: data });
}