import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  Form,
  Spin,
  Switch,
  Tabs,
  Radio,
  Modal,
  InputNumber,
  Checkbox,
  Typography,
  List,
  Select,
  DatePicker,
  Space,
} from "antd";
import {
  bankMachineList,
  bankMachineUpdate,
  csCompanyProfileSB,
  csUpdateCompanyProfileSB,
  getBankDetailList,
  newsModalNew,
  updateBankSysConf,
  updateAutoTransferStatus,
  requestBankStatement,
  addBankAcc,
  addBankAccV2,
  updateBankAccount,
  csAvailBankList,
  removeBankAcc,
  removeBankAccV2,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined, QuestionCircleOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import Highlighter from "react-highlight-words";
import { FormatPt } from "../../library/numbers";
import { bankMgmtBnkSwt, bankMgmtSbSwitch, bankMgmtAT } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import moment from "moment";
import { FormatDate } from "../../library/dates";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import {DeleteOutlined} from "@ant-design/icons"
import { bindSmsVw } from "../../library/permission";

export default function BankMachine() {
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const { Text, Paragraph } = Typography;
  const { confirm } = Modal;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [editForm] = Form.useForm();
  const { issuper , role, companyId, compCode, username } = useSelector((state) => state.login);
  const [bankData, setBankData] = useState("");
  const [depositBankData, setDepositBankData] = useState([]);
  const [withdrawBankData, setWithdrawBankData] = useState([]);
  const [companyProfileSmartBank, setCompanyProfileSmartBank] = useState(false)

  //Member Transaction 
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);

  const [tab1Title, setTab1Title] = useState("");
  const [tab2Title, setTab2Title] = useState("");

  const [settLoading, setSettLoading] = useState(false);
  const [showEditBankModal, setShowEditBankModal] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [submitData, setSubmitData] = useState({});
  
  const [rowData, setRowData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentTab, setCurrentTab] = useState('1');

  const [reqStatementVisible, setReqStatementVisible] = useState(false);
  const [formRequestStatement] = Form.useForm();
  const [reqStatementStartDate, setReqStatementStartDate] = useState("");
  const [reqStatementEndDate, setReqStatementEndDate] = useState("");
  const [reqStatementAuthToken, setReqStatementAuthToken] = useState("");
  const [submitStatementLoading, setSubmitStatementLoading] = useState(false);

  const reqStatementDateFrom = useRef(null);
  const reqStatementDateTo = useRef(null);

  //Add bank
  const [addBankModal, setAddBankModal] = useState(false);
  const [bankType, setBankType] = useState("");
  //Update bank
  const [editBankForm] = Form.useForm();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  // const modalForm = useRef();
  const [addBankForm] = Form.useForm()
  const [addBankNumber, setAddBankNumber] = useState("");
  const [accountLimit, setAccountLimit] = useState("");
  const [rank, setRank] = useState(999);
  const [transferMethod, setTransferMethod] = useState(['""']);
  const [remark, setRemark] = useState("");
  const [minDeposit, setMinDeposit] = useState(1);
  const [depositSpeed, setDepositSpeed] = useState(3);
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [bankCode, setBankCode] = useState("")
  const [accountName, setAccountName] = useState("")
  const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
  const [loadBalance, setLoadBalance] = useState(false)
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    assignTabName();
    setTab1();
    setTab2();
    getCompanyProfileSB();
    availBankList();
    console.log(role)
  }, []);

  const controlEditBankModal = () =>{
    if(showEditBankModal === false){
      setShowEditBankModal(true);
    }
    else{
      setShowEditBankModal(false);
      setRowData(null);
    }
  }
  const controlEditConfirmModal = () =>{
    if(showEditConfirmModal === false){
      setShowEditConfirmModal(true);
    }else{
      setShowEditConfirmModal(false);
      // setRowData(null);
    }
  }
  const assignTabName = () => {
    setTab1Title('Deposit');
    setTab2Title('Withdraw');
  }
  const BtnEditBank = ({record,disable}) => { 
    const handleEdit = (record) => {
      setRowData(record);
      setSelectedRow(record);
      editform.setFieldsValue({
        // Set form field values based on the selected row data
        acctname:record.acctname,
        autoTransferAmount: record.autoTransferAmount,
        maximumBalance: record.maximumBalance,
        toAccountNumber: record.toAccountNumber,
        toAccountBankCode: record.toAccountBankCode,

        // Set other form field values as needed
      });
      controlEditBankModal();
    };
    return (                
      // <Tooltip title="Edit Marquee">
          <Button type="primary"
              disabled={disable}
              style={{width:'80px'}}
              onClick={()=>{
                handleEdit(record)
              }
                
            }
          >Edit</Button>
      // </Tooltip> 
    )
}
  const onChangeTransferTab = (key)=> {
    if (key === 1) {
    }

    if (key === 2) {
    }
  }
  const handleTabChange = (key) => {
    setCurrentTab(key);
  };
  //#region Components
  const DepositAmt = (props) => {
     if (props.Amount!='') {
       return (<span>Deposit Amount :{FormatPt(props.Amount)}</span>);
     }
  }

  const CLabel = (props) => {
    return <b>{props.loading? <Spin/> : 
               props.setting ? <span style={{color: props.status=='on'?'green':props.status != undefined?'red':null,...styles.on}} loading={settLoading}>ON</span>:<span style={{color: props.status=='on'?'green':props.status != undefined?'red':null,...styles.off}} loading={settLoading}>OFF</span> }</b>
  }
  ////#endregion

  //#region Load List
  const getCompanyProfileSB = async () => {
    const resCompanyProfile = await csCompanyProfileSB({ companyId: companyId })

    let resp = await resCompanyProfile.json();
    if (resp.status === "ok" && resp.msg != 'No record found') {

      if (Object.keys(resp.data).length > 0 ) {
          setTableLoading(false);
          setCompanyProfileSmartBank(convertToBool(resp.data['smartBank']));     

          return companyProfileSmartBank;

      }
      else {
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }
    }
    else {
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
};

  const setTab1 = async () => {
    setTableLoading(true)
    
    let countBM = 0 ;
    const requestData = {
      BankType : 'Deposit'
    }
    
    const resBankMach = await getBankDetailList(requestData);
    let resp = await resBankMach.json();
    if (resp.status === "ok" && resp.msg != 'No records found') {
      if (Object.keys(resp.data).length > 0 ) {
        setTableLoading(false);
        const respArr = Object.values(resp.data);
        let newDepositBankData = respArr.map((obj) => {
              return {
                key      : ++countBM,
                // acctnum  : obj.acctnum,
                // acctname : obj.acctname,
                // username : obj.username,
                // bankcode : obj.bankcode,
                // banktype : obj.banktype, 
                // queue    : obj.queue,
                // remark   : obj.remark,
                // status   : obj.status,
                // type     : obj.type,
                ...obj,
              };
            });
            setDepositBankData(newDepositBankData);
      }
    }
    else {
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  };

  const setTab2 = async () => {
    setTableLoading(true)
    
    let countBM = 0 ;
    const requestData = {
      BankType : 'Withdraw'
    }

    const resBankMach = await getBankDetailList(requestData);
    let resp = await resBankMach.json();

    if (resp.status === "ok" && resp.msg != 'No records found') {

      if (Object.keys(resp.data).length > 0 ) {
        setTableLoading(false);
        const respArr = Object.values(resp.data);
        let newWithdrawBankData = respArr.map((obj) => {
              return {
                key      : ++countBM,
                acctnum  : obj.acctnum,
                // acctname : obj.acctname,
                // username : obj.username,
                // bankcode : obj.bankcode,
                // banktype : obj.banktype, 
                // queue    : obj.queue,
                // remark   : obj.remark,
                status   : obj.status,
                // type     : obj.type,
                ...obj

              };
            });
            setWithdrawBankData(newWithdrawBankData);

      }
    }
    else {
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  };

  const handleRequestStatement = async () => {
    setSubmitStatementLoading(true);

    if (!reqStatementStartDate) {
      setSubmitStatementLoading(false);
      reqStatementDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Statement Start Date !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!reqStatementEndDate) {
      setSubmitStatementLoading(false);
      reqStatementDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Statement End Date !";
      sharedErrorAlert(alertData);
      return;
    }

    if (reqStatementEndDate < reqStatementStartDate) {
      setSubmitStatementLoading(false);
      reqStatementDateTo.current.focus();
      alertData.title = "Validation Error";
      alertData.msg = "Statement End Date cannot early than Start Date !";
      sharedErrorAlert(alertData);
      return;
    }
    
    let requestData = {
      startDate : reqStatementStartDate.toISOString(),
      endDate : reqStatementEndDate.toISOString(),
      authToken : reqStatementAuthToken
    }

    const requestStatement = await requestBankStatement(requestData);
    let resp = await requestStatement.json();

    if (resp.status === "ok") {
      onCancelRequestStatement();
      alertData.title = "Success";
      alertData.msg = resp.msg;
      sharedSuccessAlert(alertData);
      setSubmitStatementLoading(false);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
      setSubmitStatementLoading(false);
    }
  }
  ////#endregion

  //#region Functions
  let convertToBool = (value)=>{
    if (value == null || value=='') {
      return false;
    }
    else {
       if (value.toString().toUpperCase() == 1) {
          return true;
       }
       else {
         return false;
       }
    }
  }

  const onChangeBankMachDep = async (bankcode, acctnum, status, authToken)=> {
    // let bankStatus = (status == 1) ? 'inactive' : 'active';
    let bankStatus = (status == 0) ? 'inactive' : (status == 1) ? 'active' : (status == 2) ? 'pause' : '';

    let requestData = {
      bankcode : bankcode,
      acctnum  : acctnum,
      status   : bankStatus,
      bankType : "Deposit",
      authToken: authToken,
    }

    const result = await bankMachineUpdate(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTab1();
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
    } 
  }

  const onChangeBankMachineWth = async (updateType, bankcode, acctnum, e, authToken)=> {
    let bankStatus = (e == 0) ? 'inactive' : (e == 1) ? 'active' : (e == 2) ? 'pause' : '';
    // let transferMethod = (status == true) ? 'WEB' : 'API';
    console.log(`bankcode >> ${bankcode}, acctnum >> ${acctnum}, status >> ${bankStatus}`);

    let requestData = {
      bankcode       : bankcode,
      acctnum        : acctnum,
      status         : bankStatus,
      updateType     : updateType,
      bankType       : 'Withdraw',
      authToken      : authToken,
      // transferMethod : transferMethod
    }

    const result = await bankMachineUpdate(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTab2();
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
    } 
  }

  const onChangeSmartToggle = async (data)=> {
    setCompanyProfileSmartBank(data); 
    
    let smartBankState = ''
    if (data == true) { smartBankState = 1}
    else { smartBankState = 0}

    let requestData = {
      smartBank : smartBankState,
      companyId  : companyId,
    }

    const result = await csUpdateCompanyProfileSB(requestData);
    let resp = await result.json();
    if (resp.status ==="ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTab1();
      }
      else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);  
    } 
  }

  const onCancelRequestStatement = () => {
    setReqStatementStartDate("");
    setReqStatementEndDate("");
    setReqStatementVisible(false);
  }

  const onChangeStartDatePicker = (date, dateString) => {
    setReqStatementStartDate(date);
  }

  const onChangeEndDatePicker = (date, dateString) => {
    setReqStatementEndDate(date);
  }

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });
  ////#endregion

  //#region Columns
  let bankColumns1 = [
    // {
    //   align: "center",
    //   title: "",
    //   dataIndex:"key",
    //   width: "5%",
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text;
    //   }
    // },
    {
      align: "center",
      title: <div>Auto Transfer</div>,
      dataIndex:"enableAutoTransfer",
      width: '10%',
      ellipsis: true,
      render: (text , record)=> {
        // let bankstatus = text == "1"? true: false;
        return !(bankMgmtAT(role))?
            <>
              <CLabel setting={record.enableAutoTransfer} status={record.enableAutoTransfer?'on':'off'} loading={settLoading}/>
            </>
            :
              (record.id != undefined?
                <Checkbox
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  onChange={async(e) => {
                    const autoTransferBool = text == "1" ? "0" : "1";
                    const data = {id:record.id,enableAutoTransfer:parseInt(autoTransferBool),acctnum:record.acctnum};
                    const result = await updateAutoTransferStatus(data);
                    let resp = await result.json();
                    if (resp.status === "ok") {
                        alertData.title = "Success";
                        alertData.msg = resp.msg;
                        sharedSuccessAlert(alertData);
                        setTab1();
                      }
                      else{
                        alertData.title = "Failed";
                        alertData.msg = resp.msg;
                        sharedFailAlert(alertData);  
                    } 
                    
                  }}
                  checked={text == "1" ? true: false}
                  loading={settLoading}
                />
              :
                <div>
                  <Text>Not Available</Text>
                </div>
        );        
      }
    },
    {
      align: "center",
      title: <div>Account No.</div>,
      dataIndex:"acctnum",
      width: "10%",
      ellipsis: true,
      render: (text, record)=> {
        if (record.bankcode != '' && record.bankcode == 'SCB') {
          return (
            <div>
              <span>
                <DownloadOutlined
                  onClick={() => {
                    setReqStatementVisible(true);
                    setReqStatementAuthToken(record.authToken);
                  }}
                />
              </span>
              <span
                style={{ marginLeft: "5%" }}
              >
                {record.bankcode} ({text})
              </span>
            </div>
          )
        }
        else {
          return `${record.bankcode} (${text})`;
        }
      }
    },
    {
      align: "center",
      title: <div>Account Name</div>,
      dataIndex: "acctname",
      width: "10%",
      // ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Bank Type</div>,
      dataIndex: "banktype",
      width: "10%",
      ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    // {
    //   align: "center",
    //   title: <div>Balance</div>,
    //   dataIndex: "Amount",
    //   width: "10%",
    //   ellipsis: true,
    //   render: (text)=> {
    //     return (text).toFixed(2);
    //   }
    // },
    // {
    //   align: "center",
    //   title: <div>Queue</div>,
    //   dataIndex:"queue",
    //   width: "10%",
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text;
    //   }
    // },
    {
      align: "center",
      title: <div>Remark</div>,
      dataIndex:"remark",
      width: "10%",
      ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Active</div>,
      dataIndex: "status",
      width: "10%",
      ellipsis: true,
      render: (text , record)=> {
        // let bankstatus = text == "0" ? <span style={{color: 'orange'}}>Stand By (API ON)</span> : text == "1" ? <span style={{color: 'green'}}>Active (API ON)</span> 
        let bankstatus = text == "0" ? <span style={{color: 'red', fontWeight: 'bold'}}>OFF</span> : text == "1" ? <span style={{color: 'green', fontWeight: 'bold'}}>ON</span> 
        // : text == "2" ?  <span style={{color: 'red'}}>Stop API (API OFF)</span> 
        : "";
        return (
          !(bankMgmtBnkSwt(role)) ?
              bankstatus
          :
          <Form style={{
            marginTop:"-5px",
            marginBottom:"-28px"
          }}>
            <Select placeholder="Select Deposit Status" 
                    style={{...styles.statusStyle}} 
                    value={bankstatus} 
                    key={record.key} 
                    onChange={(e) => {
                        console.log("e >>>", e)
                        onChangeBankMachDep(record.bankcode, record.acctnum, e, record.authToken);
                    }}
            >
              {text != 1 ? <Option style={{color: 'green', textAlign: 'center', fontWeight: 'bold'}} value="1">ON</Option> : ""}
              {text != 0 ? <Option style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}} value="0">OFF</Option> : ""}
              {/* {text != 1 ? <Option style={{color: 'green'}} value="1">Active (API ON)</Option> : ""}
              {text != 0 ? <Option style={{color: 'orange'}} value="0">Stand By (API ON)</Option> : ""} */}
              {/* {text != 2 ? <Option style={{color: 'red'}} value="2">Stop API (API OFF)</Option> : ""} */}
            </Select>
          </Form>
        );        
      }
    },
    {
      align: "center",
      title: <div>Action</div>,
      dataIndex:"action",
      width: '10%',
      // ellipsis: true,
      render: (text, record)=> {
        return (
          <Space>
            <Button
              type="primary"
              disabled={!bindSmsVw(role)}
              onClick={() => {
                showEditModal(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              type="danger"
              disabled={!bindSmsVw(role)}
              onClick={() => {
                handleRemove(record);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        )
      }
    },

    companyProfileSmartBank == true ?
    {
      align: "center",
      title: <div>Type</div>,
      dataIndex:"type",
      width: "10%",
      ellipsis: true,
      render: (text)=> {
        return text == 1 ? "Primary" : "Secondary";
      }
    }
    :
    { hidden: true, },

    
    // {
    //   align: "center",
    //   title: 'Action',
    //   // key: 'operation',
    //   // fixed: 'right',
    //   width: 150,
    //   render: (_,record) => {
    //   if(record.id == undefined){
    //     return <BtnEditBank record={record} disable={true} />
    //   }
    //   return <BtnEditBank record={record} />}
    // },
    // {
    //   align: "center",
    //   title: <div>Transfer Amount</div>,
    //   dataIndex:"autoTransferAmount",
    //   key:"autoTransferAmount",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text,record)=> {
    //     return record.enableAutoTransfer ==1?<div>{text}</div>:null
    //   }
    // },

    // {
    //   align: "center",
    //   title: <div>Trigger Amount</div>,
    //   dataIndex:"maximumBalance",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text,record)=> {
    //    return record.enableAutoTransfer ==1?<div>{text}</div>:null
    // }
    // },

    // {
    //   align: "center",
    //   title: <div>To Account Number</div>,
    //   dataIndex:"toAccountNumber",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text,record)=> {
    //     return record.enableAutoTransfer ==1?<div>{text}</div>:null
    //   }
    // },

    // {
    //   align: "center",
    //   title: <div>To Account Bank Code</div>,
    //   dataIndex:"toAccountBankCode",
    //   width: 0,
    //   ellipsis: true,
    //   render: (text,record)=> {
    //     return record.enableAutoTransfer ==1?<div>{text}</div>:null
    //   }
    // },
  ].filter(item => !item.hidden);

  let bankColumns2 = [
    // {
    //   align: "center",
    //   title: "",
    //   dataIndex:"key",
    //   width: "5%",
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text;
    //   }
    // },
    // {
    //   align: "center",
    //   title: <div>Auto Transfer</div>,
    //   dataIndex:"enableAutoTransfer",
    //   width: '10%',
    //   ellipsis: true,
    //   render: (text , record)=> {
    //     // let bankstatus = text == "1"? true: false;
    //     return !(bankMgmtAT(role)) ?
    //       <>
    //         <CLabel setting={record.enableAutoTransfer} status={record.enableAutoTransfer?'on':'off'} loading={settLoading}/>
    //       </>
    //       :
    //         (record.id ?
    //           <Checkbox
    //             checkedChildren="ON"
    //             unCheckedChildren="OFF"
    //             onChange={async(e) => {
    //               const autoTransferBool = text == "1" ? "0" : "1";
    //               const data = {id:record.id,enableAutoTransfer:parseInt(autoTransferBool),acctnum:record.acctnum};
    //               const result = await updateAutoTransferStatus(data);
    //               let resp = await result.json();
    //               if (resp.status === "ok") {
    //                   alertData.title = "Success";
    //                   alertData.msg = resp.msg;
    //                   sharedSuccessAlert(alertData);
    //                   setTab2();
    //                 }
    //                 else{
    //                   alertData.title = "Failed";
    //                   alertData.msg = resp.msg;
    //                   sharedFailAlert(alertData);  
    //               } 
                  
    //             }}
    //             checked={text == "1" ? true: false}
    //             loading={settLoading}
    //           />
    //           :
    //             <div>
    //               <Text>Not Available</Text>
    //             </div>
    //         );        
    //   }
    // },
    {
      align: "center",
      title: <div>Account No.</div>,
      dataIndex:"acctnum",
      width: '20%',
      render: (text, record)=> {
        // if (record.bankcode != '') {
        //   return `${record.bankcode} (${text})`;
        // }
        
        if (record.bankcode != '' && record.bankcode == 'SCB') {
          return (
            <div>
              <span>
                <DownloadOutlined
                  onClick={() => {
                    setReqStatementVisible(true);
                    setReqStatementAuthToken(record.authToken);
                  }}
                />
              </span>
              <span
                style={{ marginLeft: "5%" }}
              >
                {text}
              </span>
            </div>
          )
        }
        else {
          return `${text}`;
        }
      }
    },
    {
      align: "center",
      title: <div>Account Name</div>,
      dataIndex:"acctname",
      width: '20%',
      // ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Bank Type</div>,
      dataIndex:"banktype",
      width: '10%',
      ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Rank</div>,
      dataIndex:"rank",
      width: '10%',
      ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    // {
    //   align: "center",
    //   title: <div>Queue</div>,
    //   dataIndex:"queue",
    //   width: "10%",
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text;
    //   }
    // },
    {
      align: "center",
      title: <div>Remark</div>,
      dataIndex:"remark",
      width: '10%',
      ellipsis: true,
      render: (text)=> {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Withdraw Method</div>,
      dataIndex: "transferMethod",
      width: "10%",
      ellipsis: true,
      render: (text, record)=> {
        if (!bankMgmtBnkSwt(role)) {
          return (
            (record.bankcode == 'KBANK' && text == 'API') ?
              'API TRANSFER'
            : (record.bankcode == 'KBANK' && text == 'WEB') ?
              'WEB TRANSFER'
            :
              'API TRANSFER'
          )
        }
        else {
          return (
            //  !(bankMgmtBnkSwt(role)) ? 
              record.bankcode != 'KBANK' ?
              'API TRANSFER'
            :
              <>
                <Form style={{
                    marginTop:"-5px",
                    marginBottom:"-28px"
                  }}>
                  <Form.Item name="radio">
                    <Switch
                      style={{
                        backgroundColor : (text == "WEB") ? '#1890ff' : '#1da57a'
                      }}
                      checkedChildren="WEB TRANSFER"
                      unCheckedChildren="API TRANSFER"
                      key={text} 
                      onChange={(e) => {
                        let updateType = 'transferMethod';
                        onChangeBankMachineWth(updateType, record.bankcode, record.acctnum, e, record.authToken);
                      }} 
                      checked={(text == "WEB") ? true : false}>
                    </Switch>
                  </Form.Item>
                </Form>
              </>
          );
        }
      },
      hidden: (compCode != "TK4") ? true : false,
    },
    {
      align: "center",
      title: <div>Active</div>,
      dataIndex:"status",
      width: '10%',
      ellipsis: true,
      render: (text , record)=> {
        let bankstatus = text == "0" ? <span style={{color: 'red', fontWeight: 'bold'}}>OFF</span> : text == "1" ? <span style={{color: 'green', fontWeight: 'bold'}}>ON</span> 
        // let bankstatus = text == "0" ? <span style={{color: 'orange'}}>Stand By (API ON)</span> : text == "1" ? <span style={{color: 'green'}}>Active (API ON)</span> 
        // : text == "2" ?  <span style={{color: 'red'}}>Stop API (API OFF)</span> 
        : "";
        return (
          !(bankMgmtBnkSwt(role)) ?
              bankstatus
          :
          <Form style={{
              marginTop:"-5px",
              marginBottom:"-28px"
            }}>
              <Select placeholder="Select Withdraw Status" 
                      style={{...styles.statusStyle}} 
                      value={bankstatus} 
                      key={record.key} 
                      onChange={(e) => {
                          let updateType = 'bankMode';
                          console.log("e >>>", e)
                          onChangeBankMachineWth(updateType, record.bankcode, record.acctnum, e, record.authToken);
                      }}
              >
                {text != 1 ? <Option style={{color: 'green', textAlign: 'center', fontWeight: 'bold'}} value="1">ON</Option> : ""}
                {text != 0 ? <Option style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}} value="0">OFF</Option> : ""}
                {/* {text != 1 ? <Option style={{color: 'green'}} value="1">Active (API ON)</Option> : ""}
                {text != 0 ? <Option style={{color: 'orange'}} value="0">Stand By (API ON)</Option> : ""} */}
                {/* {text != 2 ? <Option style={{color: 'red'}} value="2">Stop API (API OFF)</Option> : ""} */}
              </Select>
          </Form>
        );        
      }
    },
    {
      align: "center",
      title: <div>Action</div>,
      dataIndex:"action",
      width: '10%',
      // ellipsis: true,
      render: (text, record)=> {
        return (
          <Space>
            <Button
              type="primary"
              disabled={!bindSmsVw(role)}
              onClick={() => {
                showEditModal(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              type="danger"
              disabled={!bindSmsVw(role)}
              onClick={() => {
                handleRemove(record);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        )
      }
    },

    companyProfileSmartBank == true ?
    {
      align: "center",
      title: <div>Type</div>,
      dataIndex:"type",
      width: '10%',
      ellipsis: true,
      render: (text)=> {
        return text == 1 ? "Primary" : "Secondary";
      }
    }
    :
    { hidden: true, },
    
    // {
    //   align: "center",
    //   title: <div>Transfer Amount</div>,
    //   dataIndex:"autoTransferAmount",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text != undefined? text:'-';
    //   }
    // },

    // {
    //   align: "center",
    //   title: <div>Trigger Amount</div>,
    //   dataIndex:"maximumBalance",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text!=undefined? text:'-';
    //   }
    // },

    // {
    //   align: "center",
    //   title: <div>To Account Number</div>,
    //   dataIndex:"toAccountNumber",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text!= undefined? text:'-';
    //   }
    // },

    // {
    //   align: "center",
    //   title: <div>To Account Bank Code</div>,
    //   dataIndex:"toAccountBankCode",
    //   width: 180,
    //   ellipsis: true,
    //   render: (text)=> {
    //     return text != undefined? text:'-';
    //   }
    // },
    // {
    //   align: "center",
    //   title: <div>Auto Transfer Status</div>,
    //   dataIndex:"enableAutoTransfer",
    //   width: 180,
    //   ellipsis: true,
    //   render: (text , record)=> {
    //     // let bankstatus = text == "1"? true: false;
    //     return (record.id != undefined?
    //         <Switch
    //           checkedChildren="ON"
    //           unCheckedChildren="OFF"
    //           onChange={async(e) => {
    //             const autoTransferBool = text == "1"? "0":"1";
    //             const data = {id:record.id,enableAutoTransfer:parseInt(autoTransferBool)};
    //             const result = await updateAutoTransferStatus(data);
    //             let resp = await result.json();
    //             if (resp.status === "ok") {
    //                 alertData.title = "Success";
    //                 alertData.msg = resp.msg;
    //                 sharedSuccessAlert(alertData);
    //                 setTab2();
    //               }
    //               else{
    //                 alertData.title = "Failed";
    //                 alertData.msg = resp.msg;
    //                 sharedFailAlert(alertData);  
    //             } 
                
    //           }}
    //           checked={text == "1" ? true: false}
    //           loading={settLoading}
    //         />:
    //         <p>Not Editable</p>
    //     );        
    //   }
    // },
    // {
    //   align: "center",
    //   title: 'Action',
    //   // key: 'operation',
    //   fixed: 'right',
    //   width: 150,
    //   render: (_,record) => {
    //   if(record.id == undefined){
    //     return <BtnEditBank record={record} disable={true} />
    //   }
    //   return <BtnEditBank record={record} />}
    // },
  ].filter(item => !item.hidden);

  const handleModalOk = async () => {
    try {
      const formData = await editform.validateFields();
      const {autoTransferAmount,maximumBalance,toAccountNumber,toAccountBankCode} = formData;
      const updateResult = await updateBankSysConf({
        id: selectedRow.id,
        accountNumber:toAccountNumber.toString().trim(),
        bank: toAccountBankCode.toString().trim(),
        autoTransferAmount: parseInt(autoTransferAmount.toString().trim()),
        maximumBalance: parseInt(maximumBalance.toString().trim()),

      })
    let resp = await updateResult.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        // controlEditBankModal();
        setButtonLoading(false);
        controlEditBankModal();
        // controlEditConfirmModal();
        if(currentTab == '1'){
          setTab1();
        }
        else if(currentTab == '2'){
          setTab2();
        }
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
        setButtonLoading(false);
        // controlEditConfirmModal();
    } 
    } catch (error) {
    }
  };
  const handleClearUnattend = (record) => {
    setButtonLoading(true);
    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: `Are you sure you want to update details for account ${record.acctname} (${record.acctnum})?`,
        onOk() {
            handleModalOk(record);
        },
        onCancel() {   
          setButtonLoading(false);     
        },
      });
  }
  const EditForm = () =>{
    const validateNumber = async(_, value) => {
      if (value && isNaN(Number(value))) {
        throw new Error('Please enter a valid number');
      }
    };
    const validatePositiveNum = async(_, value) => {
      if (value && value<0) {
        throw new Error('Minimum number input is 0');
      }
    };
    const validateBankInput = async(_, value) => {
      if (value && value==selectedRow.acctnum) {
        throw new Error('Transfer to account number cannot be the same as account number');
      }
    };
    const layout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 16 },
    };   
    const labelRender = (value) => (
      <div>
        {value}
      </div>
    );
    return(
      <Form
        {...layout}
        form={editform}
        initialValues={rowData}
        // onFinish={handleModalOk}
        // onFinish={controlEditConfirmModal}
        onFinish={handleClearUnattend}

        name="control-hooks"
        style={{ maxWidth: 600 }}
      >
        <Form.Item name="acctnum" label="Account No">
          {labelRender(`${rowData.bankcode} (${rowData.acctnum})`)}
        </Form.Item>
        <Form.Item name="acctname" label="Account Name">
          {labelRender(rowData.acctname)}
        </Form.Item>
        <Form.Item  name="autoTransferAmount" label="Transfer Amount" rules={[{ required: true, message:'The input cannot be empty'},{validator:validateNumber},{validator:validatePositiveNum}]}>
          <Input placeholder="Enter auto transfer amount" />
        </Form.Item>
        <Form.Item name="maximumBalance" label="Trigger Amount" rules={[{ required: true, message:'The input cannot be empty' },{validator:validateNumber},{validator:validatePositiveNum}]}>
          <Input placeholder="Enter trigger amount"/>
        </Form.Item>
        <Form.Item name="toAccountNumber" label="To Account Number" rules={[{ required: true, message:'The input cannot be empty'},{validator:validateNumber},{validator:validateBankInput}]}>
          <Input placeholder="Enter the account number to transfer to"/>
        </Form.Item>
        <Form.Item name="toAccountBankCode" label="To Account Bank Code" rules={[{ required: true, message:'The input cannot be empty'}]} >
          <Input placeholder="Enter the bank to transfer to"/>
        </Form.Item>
        <div style={{width:'100%', display:'flex',justifyContent:'center'}}>
          <div style={{display:'flex', width:'60%', justifyContent:'center'}}>
            <Form.Item >
              {/* <Button type="primary" onClick={controlEditBankModal} htmlType="button" style={{marginRight:10}}> */}
              <Button type="primary" loading={buttonLoading} onClick={controlEditBankModal} htmlType="button" style={{marginRight:10}}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={buttonLoading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    )
  }
  const ExpandRow = ({record}) =>{
    
    const col = [{
      title: '-- Transfer Amount:',
      content: record.autoTransferAmount,
    },
    {
      title: '-- Trigger Amount:',
      content: record.maximumBalance,
    },
    {
      title: '-- To Account Number:',
      content: record.toAccountNumber,
    },
    {
      title: '-- To Account Bank Code:',
      content: record.toAccountBankCode,
    }
  ]
    return(
      <div style={{display:'flex',flexDirection:'column',width:'25%',justifyContent:'center',alignItems:'center'}}>
        <div style={{display:'flex',flexDirection:'column',width:'80%'}}>
          <List
          itemLayout="horizontal"
          bordered={false}
          dataSource={col}
          renderItem={(item, index) => (
            <List.Item style={{ borderBottom: 'none',padding:2 }}>
              <div style={{...styles.expandRowCol}}><Text style={{fontWeight:'bold'}} ellipsis >{item.title}</Text><Text ellipsis style={{fontWeight:'normal', marginLeft:'10px'}}>{item.content}</Text></div>
            </List.Item>
          )}
          />
            <div style={{...styles.expandRowCol, display:'flex'}}>
              <BtnEditBank record={record} />
            </div>
        </div>  
      </div>  
    )
  }

  //Add Bank functions

  //Get bank code
  const availBankList = async () => {
    csAvailBankList({companyId: companyId})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setCSAvaBankListOptions(json.data);
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  };

  const clearAllAddBankFields = () => {
    setAddBankNumber("");
    setAccountLimit("");
    setBankType("");
    setAccountName("");
    setBankCode("");
    setRemark("")
    setLoadBalance(false)
}
  
  //Handle on submit add bank
  const onSubmitAddbank = async () => {

    let bankNumberValidation = addBankNumber.length >= 10 ? true : false

     //Data validation
     if (bankType == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in bank type!";
      sharedErrorAlert(alertData);
      setButtonLoading(false);
      return;
    }

    if (bankCode == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in bank code!";
      sharedErrorAlert(alertData);
      setButtonLoading(false);
      return;
    }

    if (addBankNumber == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in bank account number!";
      sharedErrorAlert(alertData);
      setButtonLoading(false);
      return;
    }

    if (!bankNumberValidation){
      alertData.title = "Validation Error"
      alertData.msg = "Please fill in a valid bank account number!"
      sharedErrorAlert(alertData);
      setButtonLoading(false);
      return;
    }

    if (accountName == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in bank account name!";
      sharedErrorAlert(alertData);
      setButtonLoading(false);
      return;
    }

    if(bankType == "Deposit"){
      if (minDeposit == ""){
        alertData.title = "Field Required";
        alertData.msg = `Please fill in minimum deposit!`;
        sharedErrorAlert(alertData);
        setButtonLoading(false);
        return;
      }

      if (depositSpeed == ""){
        alertData.title = "Field Required";
        alertData.msg = `Please fill in deposit speed!`;
        sharedErrorAlert(alertData);
        setButtonLoading(false);
        return;
      }
    }

    if (bankType == "Withdraw"){
      // if (accountLimit == ""){
      //   alertData.title = "Field Required";
      //   alertData.msg = `Please fill in ${bankType.toLowerCase()} limit!`;
      //   sharedErrorAlert(alertData);
      //   setButtonLoading(false);
      //   return;
      // }

      // if (accountLimit == 0){
      //   alertData.title = "Validation Error"
      //   alertData.msg = `${bankType} limit must not be 0!`
      //   sharedErrorAlert(alertData);
      //   setButtonLoading(false);
      //   return;
      // }

      if (bankType == "Deposit" && accountLimit > 100){
        alertData.title = "Validation Error"
        alertData.msg = `${bankType} limit must not be greater than 100%!`
        sharedErrorAlert(alertData);
        setButtonLoading(false);
        return;
      }

      if (rank == ""){
        alertData.title = "Field Required";
        alertData.msg = `Please fill in rank!`;
        sharedErrorAlert(alertData);
        setButtonLoading(false);
        return;
      }
    }

    confirm({
      className: "confirmationModalStyle",
      title: 'Confirmation',
      centered: true,
      icon: <QuestionCircleOutlined />,
      width: "500px",
      content: 
      <Text>
          Are you sure you want to add {bankType} bank?
          <br/>
          <ul>
              <li>
                  Bank Type: {bankType} 
              </li>
              {/* {bankType == "Deposit" ?  <li> Enable Load Balance : {loadBalance ? "Yes" : "No"} </li> : ""}  */}
              <li>
                  Bank Code: {bankCode} 
              </li>
              <li>
                  Bank Account Name: {accountName} 
              </li>
              <li>
                  Bank Number: {addBankNumber} 
              </li>
              {bankType == "Withdraw" ?  (
                <>
                  {/* <li> {bankType} Limit: {accountLimit} </li>  */}
                  <li> Rank: {rank} </li>                 
                </>
                ) : ""} 
              {bankType == "Deposit" ? (
                <>
                  <li>
                    Minimum {bankType}: {minDeposit}{" "}
                  </li>
                  <li>
                    {bankType} Speed: {depositSpeed}s{" "}
                  </li>
                </>
            ) : (
              ""
            )}
              <li>
                 Remark: {remark ? remark : "-"} 
              </li>
              
          
          </ul>
      </Text>,
      onOk() {
        setMinDeposit(1);
        setDepositSpeed(3);
        setTransferMethod('""');
        setIsChecked(false);
        onConfirmAddBank();
      },
      onCancel(){
        setButtonLoading(false);
      }
  })
  }

  //Handle on confirm add bank
  const onConfirmAddBank = async () => {
    setButtonLoading(true)
    const data = {
      banktype : bankType,
      bankcode : bankCode,
      loadbalance : 0,
      banknumber : addBankNumber,
      // accountlimit : bankType == "External" ? "0" : bankType == "Deposit" ? "90": accountLimit,
      accountlimit : bankType == "External" ? "0" : bankType == "Deposit" ? "90": "200000",
      transfermethod: transferMethod,
      rank: rank,
      mindeposit: bankType == "Deposit" ? minDeposit : 0,
      depositspeed: bankType == "Deposit" ? depositSpeed : 0,
      remark : remark,
      accountname : accountName,
      username : "-",
      companyid : companyId,
      createdby : username 
    }

    const resAddBank = await addBankAccV2(data)
    console.log(resAddBank)
    let response = await resAddBank.json()
    if (response.status == "ok"){
      alertData.title = "Success"
      alertData.msg = `${bankType} bank added successfully.`
      sharedSuccessAlert(alertData)
      setButtonLoading(false);
      setTab1();
      setTab2();
      getCompanyProfileSB();
    } else {
      if (response.errcode == "9999"){
        alertData.title = "Failed"
        alertData.msg = response.msg
        sharedFailAlert(alertData)
        setButtonLoading(false);
        setConfirmationModal(false)
        return
      }
      alertData.title = "Failed"
      alertData.msg = response.msg
      sharedFailAlert(alertData)
      setButtonLoading(false);

    }
    clearAllAddBankFields()
    setConfirmationModal(false)
    setAddBankModal(false)
  }



  const onCancelAddBank = () => {
    setMinDeposit(1);
    setDepositSpeed(3);
    setRank(999);
    setTransferMethod('""');
    setIsChecked(false);
    clearAllAddBankFields();
    setAddBankModal(false);
  }

  //region Update Bank
  // Update bank function
  const showEditModal = (record) => {
    setIsEditModalOpen(true);
    setEditRecord(record);
    editBankForm.setFieldsValue(record);
  };

  const updateBank = async (record) => {
    let requestData = {
      ...record,
    };

    console.log("Update request data: ", requestData);

    try {
      const updateBank = await updateBankAccount(requestData);
      const response = await updateBank.json();
      if (response.status === "ok") {
        alertData.title = "Success";
        alertData.msg = response.msg;
        sharedSuccessAlert(alertData);
        setTab1();
        setTab2();
        getCompanyProfileSB();
      } else {
        alertData.title = "Error";
        alertData.msg = response.msg;
        sharedErrorAlert(alertData);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alertData.title = "Error";
      alertData.msg = error.message;
      sharedErrorAlert(alertData);
    }
  };

  const onEditFinish = (values) => {
    console.log(values);
    const updateBankValue = {
      ...values,
      rank: parseInt(values.rank, 10),
    };
    
    console.log("updateBankValue: ", updateBankValue);    

    confirm({
      className: "confirmationModalStyle",
      title: "Confirmation",
      centered: true,
      icon: <QuestionCircleOutlined />,
      width: "500px",
      content: (
        <Text>
          Are you sure you want to update bank?
          <br />
          <ul>
            <li>Bank Name: {updateBankValue.acctname}</li>
            {updateBankValue.banktype == "Deposit" ? (
              <>
                <li>Minimum Deposit: {updateBankValue.minDeposit}</li>
                <li>Deposit Speed: {updateBankValue.speed}</li>
              </>
            ) : (
              <>
                <li>Rank: {updateBankValue.rank}</li>
                <li>Payment Gateway: {updateBankValue.transferMethod == "onyx" ? "Yes" : "No"}</li>
              </>
            )}
            <li>Remark: {updateBankValue.remark}</li>
          </ul>
        </Text>
      ),
      onOk() {
        updateBank(updateBankValue);
        setIsEditModalOpen(false);
      },
      onCancel() {},
    });
  };

  const onEditFinishFailed = (errorInfo) => {
    setIsEditModalOpen(true);
    console.log("Failed: ", errorInfo);
  };

  const onSubmitUpdateBank = async () => {
    try {
      // Validate the form fields
      await editBankForm.validateFields();

      editBankForm.submit();
    } catch (error) {
      if (error instanceof Error) {
        console.log("Validation failed:", error.message);
      } else {
        console.log("An unexpected error occurred");
      }
    }
  };

  const onCancelUpdateBank = () => {
    setIsEditModalOpen(false);
  };

  const BankEditForm = () => {
    const [editMinDeposit, setEditMinDeposit] = useState(editRecord.minDeposit || "");
    const [editDepositSpeed, setEditDepositSpeed] = useState(editRecord.speed || "");
    const [editRank, setEditRank] = useState(editRecord.rank || 999);
    const [editTransferMethod, setEditTransferMethod] = useState(editRecord.transferMethod == "onyx" ? true : false);
    console.log(editRecord);

    const layout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 22 },
    };
    const labelRender = (value) => <div>{value}</div>;
    return (
      <Form
        layout="vertical"
        form={editBankForm}
        onFinish={onEditFinish}
        onFinishFailed={onEditFinishFailed}
        name="control-hooks"
        centered="true"
      >
        <Form.Item label="Bank Type" name="banktype" required={true}>
          <Select disabled={true}></Select>
        </Form.Item>
        <Form.Item label="Bank Code" name="bankcode" required>
          <Select disabled={true}></Select>
        </Form.Item>
        <Form.Item label="Bank Number" name="acctnum" required>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="acctname"
          label="Bank Name"
          rules={[
            {
              required: true,
              message: "Please input your bank name!",
            },
          ]}
        >
          <Input placeholder="Bank Name" />
        </Form.Item>

        {editRecord.banktype == "Deposit" ? (
          <>
            <Form.Item 
            name="minDeposit" 
            label="Minimum Deposit" 
            rules={[
              {
                required: true,
                message: "Please input minimum deposit!",
              },
            ]}
            >
              <Input
                placeholder="Minimum Deposit"
                value={editMinDeposit}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D|^0+/g, "");
                  setEditMinDeposit(value);
                  editBankForm.setFieldsValue({ minDeposit: value });
                }}
              />
            </Form.Item>

            <Form.Item 
            name="speed" 
            label="Deposit Speed" 
            rules={[
              {
                required: true,
                message: "Please input minimum deposit!",
              },
            ]}
            >
              <Input
                placeholder="Deposit Speed"
                value={editDepositSpeed}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D|^0+/g, "");
                  setEditDepositSpeed(value);
                  editBankForm.setFieldsValue({ speed: value });
                }}
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item 
            name="rank" 
            label="Rank"
            rules={[
              {
                required: true,
                message: "Please input minimum deposit!",
              },
            ]}
            >
              <Input
                placeholder="Rank"
                value={editRank}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setEditRank(value);
                  editBankForm.setFieldsValue({ rank: value });
                }}
              />
            </Form.Item>

            <Form.Item name="transferMethod">
              <Checkbox
                checked={editTransferMethod}
                onChange={(e) => {
                  console.log(e.target.checked);
                  setEditTransferMethod(e.target.checked ? true : false);
                  editBankForm.setFieldsValue({ transferMethod: e.target.checked ? 'onyx' : '""' });
                }}
              >
                Payment Gateway
              </Checkbox>
            </Form.Item>
          </>
        )}
        <Form.Item name="remark" label="Remark" required={false}>
          <Input />
        </Form.Item>
      </Form>
    );
  };

  // Remove bank function
  const handleRemove = async (record) => {
    let msg = "Are you sure you want to remove the following bank?"
    console.log(record)
    confirm({
      className: "confirmationModalStyle",
      title: 'Confirmation',
      width: '500px',
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: 
          <Paragraph>{msg}
              <ul>
                  <li>
                      Account No. :  {record.acctnum}
                  </li>
                  <li>
                      Account Name :  {record.acctname}
                  </li>
                  <li>
                      Bank :  {record.bankcode}
                  </li>
              </ul>
          </Paragraph>,
      onOk() {
        setTableLoading(true);
        handleRemoveBank(record)
      },
      onCancel() {
        setTableLoading(false)
      }
    })
  }

  const handleRemoveBank = async (record) => {
    setButtonLoading(true)
    const data = {
      banktype : record.banktype,
      banknumber : record.acctnum,
      accountname : record.acctname,
      bankcode : record.bankcode
    }

    //Return alert if selected bank is active
    if (record.status == 1){
      alertData.title = "Warning"
      alertData.msg = `Please turn off the ${record.banktype} bank before proceeding to delete!`
      sharedErrorAlert(alertData)
      setButtonLoading(false)
      setTableLoading(false)
      return
    }

    const resRemoveBank = await removeBankAccV2(data)
    let response = await resRemoveBank.json()
    if (response.status == "ok"){
      alertData.title = "Success"
      alertData.msg = response.msg
      sharedSuccessAlert(alertData)
      setButtonLoading(false);
      setTableLoading(false)
      setTab1();
      setTab2();
      getCompanyProfileSB();
    } else {
      alertData.title = "Failed"
      alertData.msg = response.msg
      sharedFailAlert(alertData)
      setButtonLoading(false);
      setTableLoading(false)
    }
  }
 
  ////#endregion

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    expandRowCol: {
      marginLeft:'30px',
      width:'100%',
      display:'flex',
      flexDirection:'row',
      height:'30px',
    },
    expandRowColTitle:{
      // textAlign:'center' ,
      fontWeight:'bold',
      fontSize:'13px',
    },
    expandRowColContent:{
      // textAlign:'center' ,
      marginLeft:10,
      fontSize:'13px',


    },
    memberBtn: {
      width:"150px", 
      marginBottom:"8px"
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      paddingTop: 24,
      paddingLeft: 24,
    },
    tableDivide50: {
      height:"60%",
    },
    tableDivide40: {
      height:"30%",
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    bankAmountStyle:{
      marginLeft:"auto",
      marginRight:0,
    },
    tagStyle:{
      width:"92px",
    },
    memberSearchStyle:{
      width:180,
    },
    memberTrxnStyle:{
      marginLeft:"280px",
    },
    attn: {
      fontSize:"20px",
    },
    noHighlight : {
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none"
    },
    statusStyle: {
      // marginLeft: 10,
      marginBottom: 20,
      width: 140,
    },
  };
  return (
    <div>
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          {/* <div> */}
          <div>
            <Button
              style={styles.refreshBtn}
              type="primary"
              onClick={() => {
                setTab1();
                setTab2();
                getCompanyProfileSB();
              }}
            >
              Refresh Page
            </Button>
          
            {role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior || role == "Company" ? <Button
              style={styles.refreshBtn}
              onClick={()=> setAddBankModal(true)}
              type="primary">
              Add
            </Button> : ""}
            
          </div>
          <Modal 
          title="Setup Bank"
          closable={false}
          visible={addBankModal}
          centered="true"
          footer={[
            <Button loading={buttonLoading} type="primary" onClick={onSubmitAddbank}>Submit</Button>,
            <Button loading={buttonLoading} onClick={onCancelAddBank}>Cancel</Button>
          ]}>
            <Form
              form={addBankForm}
              layout="vertical"
            >
              <Form.Item
                label="Bank Type"
                required={true}
              >
                <Select
                  value={bankType}
                  onChange={(value) => {setBankType(value)}}
                >
                  <Option key="deposit" value="Deposit">Deposit</Option>
                  <Option key="withdraw" value="Withdraw">Withdraw</Option>
                  <Option key="external" value="External">External</Option>

                </Select>
              </Form.Item>
              {/* {bankType == "Deposit" ?
                <Form.Item
                  
                >
                  <Checkbox onChange={() => setLoadBalance(!loadBalance)}>Enable load balance</Checkbox>
                </Form.Item>
              :""} */}
              <Form.Item
                label="Bank Code"
                required
              >
                <Select
                  placeholder = "Select bank code"
                  value = {bankCode}
                  onChange={(value) => {setBankCode(value)}}
                  
                >
                  {csAvaBankList.map((option) => {
                    return (
                      <Option key={option.bankCode} value={option.bankCode}>
                        {option.bankName}
                      </Option>
                    )
                  })}
                </Select>

              </Form.Item>
              <Form.Item
                name="addbanknumber"
                label="Bank Number"
                required
                
              >
                <Input.Group compact>
                  <Input
                    defaultValue=""
                    placeholder="Bank number"
                    value={addBankNumber}
                    onChange={(e) => {
                      setAddBankNumber(e.target.value.replace(/\D/g,""));
                    }}
                  />
                </Input.Group>

              </Form.Item>
              <Form.Item
                name="accountname"
                label="Bank Account Name"
                required
              >
                <Input.Group compact>
                  <Input
                    defaultValue=""
                    placeholder="Bank Account Name"
                    value={accountName}
                    onChange={(e) => {
                      setAccountName(e.target.value);
                    }}
                  />
                </Input.Group>

              </Form.Item>
              {bankType == "Deposit" ? (
                <>
                  <Form.Item name="mindeposit" label="Minimum Deposit" required>
                    <Input.Group compact>
                      <Input
                        placeholder="Minimum Deposit"
                        min={1}
                        value={minDeposit}
                        onChange={(e) => {
                          setMinDeposit(e.target.value.replace(/\D|^0+/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item>

                  <Form.Item name="depositspeed" label="Deposit Speed" required>
                    <Input.Group compact>
                      <Input
                        placeholder="Deposit Speed"
                        value={depositSpeed}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D|^0+/g, "");
                          // if (value && Number(value) < 3) {
                          //   value = 3;
                          // }
                          setDepositSpeed(value);
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </>
              ) : (
                ""
              )}

              {bankType == "Withdraw" ? (
                <>
                  {/* <Form.Item
                    name="accountlimit"
                    label={
                      bankType == "Deposit"
                        ? bankType + " Limit Checkpoint (%)"
                        : bankType + " Limit"
                    }
                    required
                  >
                    <Input.Group>
                      <Input
                        style={{ width: "150px" }}
                        placeholder={bankType + " Limit"}
                        value={accountLimit}
                        onChange={(e) => {
                          setAccountLimit(e.target.value.replace(/\D/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item> */}

                  <Form.Item
                    name="rank"
                    label="Rank"
                    required
                  >
                    <Input.Group>
                      <Input
                        style={{ width: "150px" }}
                        placeholder="Rank"
                        value={rank}
                        onChange={(e) => {
                          setRank(e.target.value.replace(/\D/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item>

                  <Form.Item name="paymentGatewayBank">
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        console.log(e.target.checked ? "onyx" : '""');
                        setTransferMethod(e.target.checked ? "onyx" : '""');
                      }}
                    >
                      Payment Gateway
                    </Checkbox>
                  </Form.Item>
                </>
              ) : (
                ""
              )}

              <Form.Item
                name="remark"
                label="Remark"
                required={false}
              >
                <Input.Group>
                  <Input
                    defaultValue=""
                    placeholder="remark"
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value)
                    }}
                  />
                </Input.Group>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Update Bank"
            closable={false}
            visible={isEditModalOpen}
            centered="true"
            footer={[
              <Button
                key="updateSubmitBtn"
                loading={buttonLoading}
                type="primary"
                onClick={onSubmitUpdateBank}
              >
                Submit
              </Button>,
              <Button
                key="updateCancelBtn"
                loading={buttonLoading}
                onClick={onCancelUpdateBank}
              >
                Cancel
              </Button>,
            ]}
          >
            <BankEditForm />
          </Modal>
              
          {/* {
            !(bankMgmtSbSwitch(role))?
            ""
            :
          <div>
            <div style={{float:"left", marginTop:"5px"}}>
              <span><b>Smart Bank: </b></span> 
            </div>
          </div>
          }
          
          {
            !(bankMgmtSbSwitch(role))?
            ""
            :
            <div>
              <div style={{float:"left", marginTop:"5px"}}>
                  <Switch style={{marginLeft:"25%"}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={(e) => {
                      onChangeSmartToggle(e);
                    }}
                    checked={companyProfileSmartBank}
                    loading={settLoading}
                  />
                </div>
            </div>
          } */}
          
        </div>
      </PageHeader>
      <div style={styles.tabelContainer}>
        {/* <Tabs defaultActiveKey="1" id="report-daily-sales-tab" className="report-daily-sales-tab"> */}
        <Tabs activeKey={currentTab} onChange={handleTabChange} id="report-daily-sales-tab" className="report-daily-sales-tab">
          <TabPane tab={tab1Title} key="1" style={styles.tabHeader}>
            <div style={{height:"60%"}}>
              <p style={styles.attn}></p>
              <Table
              
                  columns={bankColumns1}
                  dataSource={depositBankData}
                  loading={tableLoading}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: styles.paginationStyle,
                    alignmentBottom: 'right',
                  }}
                  // scroll={{x:1600}}
                  expandable={{
                    expandIconColumnIndex: -1,
                    expandedRowKeys: depositBankData.map(item => item.key),
                    rowExpandable: (record)=>record.enableAutoTransfer == 1 && bankMgmtAT(role)?true:false,
                    expandedRowRender: (record) => <ExpandRow record={record}/>
                    // <div style={{display:'flex',marginLeft:30}}>
                    // <div style={styles.expandRowCol}>
                    //   <div style={styles.expandRowColTitle}><Text ellipsis >Auto Transfer Amount</Text></div>
                    //   <div style={styles.expandRowColContent}><Text ellipsis>{record.autoTransferAmount}</Text></div>
                    // </div>
                    // <div style={styles.expandRowCol}>
                    //   <div style={styles.expandRowColTitle}><Text ellipsis>Maximum Balance</Text></div>
                    //   <div style={styles.expandRowColContent}><Text ellipsis>{record.maximumBalance}</Text></div>
                    // </div>
                    // <div style={styles.expandRowCol}>
                    //   <div style={styles.expandRowColTitle}><Text ellipsis>To Account Number</Text></div>
                    //   <div style={styles.expandRowColContent}><Text ellipsis>{record.toAccountNumber}</Text></div>
                    // </div>
                    // <div style={styles.expandRowCol}>
                    //   <div style={styles.expandRowColTitle}><Text ellipsis >To Account Bank Code</Text></div>
                    //   <div style={styles.expandRowColContent}><Text ellipsis >{record.toAccountBankCode}</Text></div>
                    // </div>
                    // <div style={{...styles.expandRowCol,marginLeft:'50px', display:'flex',justifyContent:'flex-end'}}>
                    //   {/* <Button type="primary" style={{width:'80px'}} onClick={controlEditBankModal}>Edit</Button> */}
                    //   <BtnEditBank record={record} />
                    // </div>
                    // </div>
                  }}
                  
                />
              
            </div>
            <div style={{height:"40%"}}>
            
            </div>
          </TabPane>
          <TabPane tab={tab2Title} key="2" style={styles.tabHeader}>
            <div style={{height:"60%"}}>
              <p style={styles.attn}></p>
              <Table
                columns={bankColumns2}
                dataSource={withdrawBankData}
                loading={tableLoading}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptions,
                  style: styles.paginationStyle,
                  alignmentBottom: 'right',
                }}
                // size="small"
                  // scroll={{x:1600}}
                  // style={{width:'1300px'}}
                  expandable={{
                    expandIconColumnIndex: -1,
                    expandedRowKeys: withdrawBankData.map(item => item.key),
                    rowExpandable: (record)=>record.enableAutoTransfer == 1 && bankMgmtAT(role)?true:false,
                    expandedRowRender: (record) => <ExpandRow record={record}/>
                  }}
              />
            </div>
            <div style={{height:"40%"}}>
            </div>
          </TabPane>
        </Tabs>
        <Modal
          title="Edit Form"
          open={showEditBankModal}
          // onOk={handleModalOk}
          // onOk={controlEditConfirmModal}
          onOk={handleClearUnattend}
          onCancel={controlEditBankModal}
          footer={null}
        >
          <EditForm />
        </Modal>
        {/* <Modal
          title="Confirmation"
          open={showEditConfirmModal}
          onOk={handleModalOk}
          onCancel={()=>{controlEditConfirmModal()}}
          style={{textAlign:'center', marginTop:'100px'}}
          // footer={null}
        ><Text>Are you sure you want to update the auto transfer details?</Text></Modal> */}

          <Modal 
            title="Request Statement Form"
            width={600}
            visible={reqStatementVisible}
            maskClosable={false}
            closable={false}
            getContainer={false}
            okButtonProps={{ 
              style: styles.hide
            }}
            cancelText="Close"
            cancelButtonProps={{ 
              type: "primary",
              loading: submitStatementLoading
            }}   
            onCancel ={() => {
                onCancelRequestStatement();
            }} 
          >
            <div>
              <Form 
                form={formRequestStatement} 
                layout="horizontal" 
                name="reqStatementForm"
              >
                <div style={styles.pageHeaderContainer}>
                  <DatePicker
                    key="1"
                    style={{
                      width: "200px"
                    }}
                    format="YYYY-MM-DD"
                    onChange={onChangeStartDatePicker}
                    value={reqStatementStartDate}
                    ref={reqStatementDateFrom}
                    refs
                    placeholder={"Select Start Date"}
                    disabledDate={(current)=> {
                      return current > moment();//.subtract(1, 'day');
                    }}
                  />

                  <DatePicker
                    key="2"
                    style={{
                      width: "200px",
                      marginLeft: "3%"
                    }}
                    format="YYYY-MM-DD"
                    onChange={onChangeEndDatePicker}
                    value={reqStatementEndDate}
                    ref={reqStatementDateTo}
                    refs
                    placeholder={"Select End Date"}
                    disabledDate={(current)=> {
                      return current > moment();//.subtract(1, 'day');
                    }}
                  />

                  <Button
                    style={{
                      marginLeft: "3%"
                    }}
                    type="primary"
                    onClick={()=>{
                      handleRequestStatement();
                    }}
                    loading={submitStatementLoading}
                  >
                    Request
                  </Button>
                </div>
                
              </Form>
            </div>
          </Modal>
          <p>
            <b>REMARK :</b>
          </p>
          {/* <p>
            <b><span style={{color:"blue"}}>[1]</span></b> <span style={{color:"orange"}}>Stand By (API ON)</span> and <span style={{color:"green"}}>Active (API ON)</span> = API is currently running. Hence, SCB Bank App cannot be <b>access</b>.
          </p>
          <p>
            <b><span style={{color:"blue"}}>[2]</span></b> <span style={{color:"red"}}>Stop API (API OFF) </span> = API is not running. Hence, SCB Bank App can be <b>access</b>.
          </p> */}
          <p>
            <b><span style={{color:"blue"}}>[1]</span></b> Only <span style={{color:"red"}}><b>(ONE)</b></span> Deposit bank can be <span style={{color:"red"}}><b>active</b></span> at a time.
          </p>
          <p>
            <b><span style={{color:"blue"}}>[2]</span></b> <i><b>"Auth key not found, please check Bank Account Configuration"</b></i> = The selected bank does not have API enabled, please contact IT if need to setup API.
          </p>
          <br></br>
      </div>
    </div>
  );
}
