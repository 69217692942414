import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Tooltip,
  Switch,
  message,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import {
  companyProfile,
  companyProfileUpdate,
} from "../../config/networkConfig";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";

export default function CompanyProfile() {
  const [form] = Form.useForm();
  const { userId } = useSelector((state) => state.login);
  const [apiVer, setApiVer] = useState("");
  const [appName, setAppName] = useState("");
  const [appVer, setAppVer] = useState("");
  const [compBrand, setCompBrand] = useState("");
  const [compCode, setCompCode] = useState("");
  const [compName, setCompName] = useState("");
  const [ctryCode, setCtryCode] = useState("");
  const [wthETA, setWthETA] = useState(0);
  const [depMin, setDepMin] = useState(0);
  const [depMax, setDepMax] = useState(0);
  const [wthMin, setWthMin] = useState(0);
  const [wthMax, setWthMax] = useState(0);
  const [sysFee, setSysFee] = useState(0);
  const [gameExit, setGameExit] = useState(false);
  const [gameLanding, setGameLanding] = useState(false);
  const [registerClear, setRegisterClear] = useState(false);
  const [dwLink, setDwLink] = useState("");
  const [lineID, setLineID] = useState("");
  const [mainUrl, setMainUrl] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const [subUrl, setSubUrl] = useState([]);
  const [spinLoading, setSpinLoading] = useState(true);

  useEffect(() => {
    companyData();

    // eslint-disable-next-line
  }, []);

  const getformValue = (name) => {
    return form.getFieldValue(name);
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
  };

  const subItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      span: 17,
      offset: 8,
    },
  };

  const companyData = () => {
    companyProfile({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        const data = json.data;
        form.setFieldsValue({
          apiVer: data.apiVer,
          appName: data.appName,
          appVer: data.appVer,
          compBrand: data.compBrand,
          compCode: data.compCode,
          compName: data.compName,
          ctryCode: data.ctryCode,
          sysFee: data.sysFee,
          dwLink: data.dwLink,
          lineID: data.lineID,
          mainUrl: data.mainUrl,
          secretKey: data.secretKey,
          shareUrl: data.shareUrl,
          subUrls: data.subUrl,
          depMin: data.depMin,
          depMax: data.depMax,
          wthMin: data.wthMin,
          wthMax: data.wthMax,
          wthETA: data.wthETA,
        });
        setGameExit(data.gameExit ? true : false);
        setGameLanding(data.gameLanding ? true : false);
        setRegisterClear(data.registerClear ? true : false);
        setSubUrl(data.subUrl);
        setSpinLoading(false);
      });
  };

  const onFinish = async (values) => {
    setSpinLoading(true);
    console.log("Received values of form: ", values);
    try {
      await form.validateFields();
      companyProfileUpdate({
        userId: userId,
        compCode: values.compCode,
        compBrand: values.compBrand,
        compName: values.compName,
        ctryCode: values.ctryCode,
        secretKey: values.secretKey,
        sysFee: values.sysFee,
        registerClear: registerClear ? 1 : 0,
        gameLanding: gameLanding ? 1 : 0,
        gameExit: gameExit ? 1 : 0,
        depMin: values.depMin,
        depMax: values.depMax,
        wthMin: values.wthMin,
        wthMax: values.wthMax,
        trxfMin: values.trxfMin,
        trxfMax: values.trxfMax,
        appName: values.appName,
        appVer: values.appVer,
        apiVer: values.apiVer,
        dwLink: values.dwLink,
        lineID: values.lineID,
        mainUrl: values.mainUrl,
        subUrl: values.subUrls.join(","),
        shareUrl: values.shareUrl,
        wthETA: values.wthETA,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            alertData.title = "Success";
            alertData.msg = "System Profile is updated.";
            sharedSuccessAlert(alertData);
            setSpinLoading(false);
            companyData();
            return;
          }
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          setSpinLoading(false);
        });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <Spin spinning={spinLoading} size="large">
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
        initialValues={{
          subUrls: subUrl,
        }}
      >
        <Form.Item label="Api Version">
          <Form.Item
            name="apiVer"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Api version.",
              },
            ]}
          >
            <Input
              value={apiVer}
              onChange={(e) => {
                setApiVer(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="App Name">
          <Form.Item
            name="appName"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input App Name.",
              },
            ]}
          >
            <Input
              value={appName}
              onChange={(e) => {
                setAppName(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="App Version">
          <Form.Item
            name="appVer"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input App Version.",
              },
            ]}
          >
            <Input
              value={appVer}
              onChange={(e) => {
                setAppVer(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Secret Key">
          <Form.Item
            name="secretKey"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Secret Key.",
              },
            ]}
          >
            <Input
              value={secretKey}
              onChange={(e) => {
                setSecretKey(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Company Brand">
          <Form.Item
            name="compBrand"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Company Brand.",
              },
            ]}
          >
            <Input
              value={compBrand}
              onChange={(e) => {
                setCompBrand(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Company Code">
          <Form.Item
            name="compCode"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Company Code.",
              },
            ]}
          >
            <Input
              value={compCode}
              onChange={(e) => {
                setCompCode(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Company Name">
          <Form.Item
            name="compName"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Company Name.",
              },
            ]}
          >
            <Input
              value={compName}
              onChange={(e) => {
                setCompName(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Country Code">
          <Form.Item
            name="ctryCode"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Country Code.",
              },
            ]}
          >
            <Input
              value={ctryCode}
              onChange={(e) => {
                setCtryCode(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="System Fee">
          <Form.Item
            name="sysFee"
            noStyle
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: "Please input System Fee.",
                transform(value) {
                  return Number(value);
                },
              },
            ]}
          >
            <Input
              value={sysFee}
              onChange={(value) => {
                setSysFee(value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Deposit"
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(30% - 0px)",
            }}
            name="depMin"
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: "Please input Deposit Minimum.",
              },
            ]}
          >
            <InputNumber
              style={{ width: 130 }}
              value={depMin}
              placeholder="Minimum"
              onChange={(value) => {
                setDepMin(value);
              }}
            />
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "30px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          >
            -
          </span>
          <Form.Item
            name="depMax"
            style={{
              display: "inline-block",
              width: "calc(30% - 0px)",
            }}
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: `Please input more than ${getformValue("depMin")}.`,
                min: getformValue("depMin"),
              },
            ]}
          >
            <InputNumber
              value={depMax}
              style={{
                width: 130,
                textAlign: "center",
              }}
              placeholder="Maximum"
              onChange={(value) => {
                setDepMax(value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Withdraw"
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(30% - 0px)",
            }}
            name="wthMin"
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: "Please input Withdraw Minimum.",
              },
            ]}
          >
            <InputNumber
              style={{ width: 130 }}
              value={wthMin}
              placeholder="Minimum"
              onChange={(value) => {
                setWthMin(value);
              }}
            />
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "30px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          >
            -
          </span>
          <Form.Item
            name="wthMax"
            style={{
              display: "inline-block",
              width: "calc(30% - 0px)",
            }}
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: `Please input more than ${getformValue("wthMin")}.`,
                min: getformValue("wthMin"),
              },
            ]}
          >
            <InputNumber
              value={wthMax}
              style={{
                width: 130,
                textAlign: "center",
              }}
              placeholder="Maximum"
              onChange={(value) => {
                setWthMax(value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Withdraw ETA">
          <Form.Item
            name="wthETA"
            noStyle
            rules={[
              {
                type: "number",
                required: true,
                whitespace: true,
                message: "Please input Withdraw ETA.",
                transform(value) {
                  return value ? Number(value) : undefined;
                },
              },
            ]}
          >
            <Input
              value={wthETA}
              onChange={(value) => {
                setWthETA(value);
              }}
              style={{
                width: 145,
              }}
              maxLength={5}
              addonAfter="seconds"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Game Exit">
          <Form.Item name="gameExit" noStyle>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setGameExit(e);
              }}
              checked={gameExit}
            />
          </Form.Item>
          <Tooltip title="When user exit game display prompt dialog">
            <a href="#API" style={{ margin: "0 8px", color: "orange" }}>
              Help?
            </a>
          </Tooltip>
        </Form.Item>

        <Form.Item label="Game Landing">
          <Form.Item name="gameLanding" noStyle>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setGameLanding(e);
              }}
              checked={gameLanding}
            />
          </Form.Item>
          <Tooltip title="Display game landing page">
            <a href="#API" style={{ margin: "0 8px", color: "orange" }}>
              Help?
            </a>
          </Tooltip>
        </Form.Item>

        <Form.Item label="Register Clear">
          <Form.Item name="registerClear" noStyle>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setRegisterClear(e);
              }}
              checked={registerClear}
            />
          </Form.Item>
          <Tooltip title="Register Clear">
            <a href="#API" style={{ margin: "0 8px", color: "orange" }}>
              Help?
            </a>
          </Tooltip>
        </Form.Item>

        <Form.Item label="Share URL">
          <Form.Item
            name="shareUrl"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Share URL.",
              },
            ]}
          >
            <Input
              value={shareUrl}
              onChange={(e) => {
                setShareUrl(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Download Link">
          <Form.Item
            name="dwLink"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Download Link.",
              },
            ]}
          >
            <Input
              value={dwLink}
              onChange={(e) => {
                setDwLink(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="LINE ID">
          <Form.Item
            name="lineID"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input LINE ID.",
              },
            ]}
          >
            <Input
              value={lineID}
              onChange={(e) => {
                setLineID(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Main URL">
          <Form.Item
            name="mainUrl"
            noStyle
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input Main Link.",
              },
            ]}
          >
            <Input
              value={mainUrl}
              onChange={(e) => {
                setMainUrl(e.target.value);
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.List name="subUrls">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      {...(index === 0
                        ? subItemLayout
                        : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "Sub URL" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        name={"subUrl"}
                        {...field}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input sub URL or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          value={subUrl}
                          placeholder={`${index + 1} Sub URL `}
                          onChange={(e) => {
                            setSubUrl(e.target.value);
                          }}
                          style={{ width: "44%" }}
                        />
                      </Form.Item>
                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}
                <Form.Item label=" " colon={false}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "100%" }}
                  >
                    <PlusOutlined /> Add sub URL
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
